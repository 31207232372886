export const ReactTabsStylings = {
  tabContainer: {
    padding: '1rem'
  },
  tabTitle: {
    paddingBottom: '0.5rem',
    cursor: 'pointer'
  },
  activeTab: {
    textDecoration: 'none',
    borderBottom: '3px solid #5932EA'
  },
  inactiveTab: {
    textDecoration: 'none'
  }
};
