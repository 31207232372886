import { React } from 'react';

import AddVehicleInfo from './addVehicleInfo';
import AddTyres from './addTyres';

const Vehicles = () => {
  return (
    <>
      <AddVehicleInfo />
      <hr />
      <AddTyres />
    </>
  );
};
export default Vehicles;
