import * as Yup from 'yup';

export const loginValidations = Yup.object({
  userEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  userPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required')
});

export const inviteUserValidations = Yup.object({
  userName: Yup.string()
    .min(2, 'Employee Name must be at least 2 characters')
    .max(30, 'Employee Name cannot be greater than 30 charcters')
    .matches(/^\S.*$/, 'Cannot start with a space')
    .required('Employee name is required')
    .matches(/^[A-Za-z\s]+$/, 'Name should not contain numbers'),
  userEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Employee email is required'),
  userRole: Yup.string().required('Please select an employee role'),
  branchNames: Yup.array()
    .min(1, 'Please select at least one branch')
    .required('Branch selection is required'),
  userMobileNumber: Yup.string()
    .matches(/^\d{10}$/, 'Please enter a valid 10-digit mobile number')
    .required('Employee mobile number is required')
});

export const organisationValidations = Yup.object({
  organisationName: Yup.string()
    .required('Organisation Name is required.')
    .matches(/^\S.*$/, 'Cannot start with a space')
    .min(6, 'Organisation Name must be at least 6 characters.')
    .max(25, 'Organisation Name cannot exceed 25 characters')
    .matches(/^[A-Za-z\s]+$/, 'Name should not contain numbers'),
  numLocations: Yup.number().required(
    'Number of business locations are required.'
  ),
  organisationProfile: Yup.mixed()
    .required('An image is required.')
    .test(
      'fileType',
      'Unsupported file format. Upload a valid image file.',
      value =>
        !value || (value && ['image/jpeg', 'image/png'].includes(value.type))
    )
});

export const createBranchesValidation = Yup.object({
  branchName: Yup.string()
    .required('Branch Name is required')
    .min(6, 'Branch Name must be at least 6 characters.')
    .max(25, 'Branch Name cannot exceed 25 characters')
    .matches(/^\S.*$/, 'Cannot start with a space')
    .matches(/^[A-Za-z\s]+$/, 'Name should not contain numbers'),
  branchAddress: Yup.string()
    .required('Location is required')
    .matches(/^\S.*$/, 'Cannot start with a space'),
  branchMobileNumber: Yup.string()
    .required('Mobile Number is required')
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits'),
  storeAddressLink: Yup.string()
    .required('Store Link is required')
    .url('Invalid URL format')
});

export const setPasswordValidations = Yup.object({
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(
      /[^a-zA-Z0-9]/,
      'Password must contain at least one special character'
    )
    .required('Password is required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm Password is required')
});

export const heavyVehicleBillingValidations = Yup.object().shape({
  customerName: Yup.string()
    .required('Customer Name is required')
    .matches(/^\S.*$/, 'Cannot start with a space')
    .min(2, 'Customer Name must be at least 2 characters')
    .max(25, 'Customer Name cannot be greater than 25 charcters')
    .matches(/^[A-Za-z.\s]+$/, 'Name should not contain numbers'),
  customerMobileNumber: Yup.string()
    .required('Mobile Number is required')
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits'),
  driverMobileNumber: Yup.string()
    .required('Driver Mobile Number is required')
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits'),
  vehicleNumber: Yup.string()
    .required('Vehicle Number is required')
    .min(6, 'Vehicle Number must be at least 6 characters long')
    .max(20, 'Vehicle Number cannot be greater than 20 charcters'),
  vehicleManufacturer: Yup.string().required(
    'Vehicle Manufacturer is required'
  ),
  vehicleModel: Yup.string().required('Vehicle Model is required'),
  numberOfVehicleWheels: Yup.string().required('Number of Wheels is required'),
  vehicleOdometerReading: Yup.number().required(
    'Vehicle Odometer Reading is required'
  ),
  vehicleAverageKmsPerDay: Yup.number().required(
    "Average KM's Per Day is required"
  ),
  gstinNumber: Yup.string().when('useGstInvoice', {
    is: true,
    then: () =>
      Yup.string()
        .required('GSTIN Number is required')
        .min(6, 'GSTIN Number must be at least 6 characters.')
        .max(25, 'GSTIN Number cannot exceed 25 characters')
        .matches(/^\S.*$/, 'Cannot start with a space'),
    otherwise: () => Yup.string()
  }),
  businessName: Yup.string().when('useGstInvoice', {
    is: true,
    then: () =>
      Yup.string()
        .required('Business Name is required')
        .matches(/^\S.*$/, 'Cannot start with a space')
        .min(6, 'Business Name must be at least 6 characters.')
        .max(25, 'Business Name cannot exceed 25 characters')
        .matches(/^[A-Za-z\s]+$/, 'Name should not contain numbers'),
    otherwise: () => Yup.string()
  }),
  services: Yup.object().shape({
    wheelAlignment: Yup.object().shape({
      wheelAlignmentPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    wheelBalancing: Yup.object().shape({
      wheelBalancingPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      wheelBalancingQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    weightBalancing: Yup.object().shape({
      weightBalancingPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      weightBalancingQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    tyreChanging: Yup.object().shape({
      tyreChangingPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      tyreChangingQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    tyreRotation: Yup.object().shape({
      tyreRotationPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      tyreRotationQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    tyrePuncture: Yup.object().shape({
      tyrePuncturePrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      tyrePunctureQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    sims: Yup.object().shape({
      simsPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      simsQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    nitrogen: Yup.object().shape({
      nitrogenPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      nitrogenQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.number()
      })
    }),
    others: Yup.object().shape({
      othersPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      othersQuantity: Yup.string().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Description is required')
            .matches(/^\S.*$/, 'Cannot start with a space')
            .min(10, 'Description cannot be less than 10 charcters')
            .max(20, 'Description cannot be greater than 20 charcters'),
        otherwise: () => Yup.string()
      })
    }),
    freight: Yup.object().shape({
      selected: Yup.string()
        .required('Freight status is required')
        .oneOf(['Yes', 'No'], 'Please select either Yes or No'),
      freightCharge: Yup.number().when('selected', {
        is: 'Yes',
        then: () =>
          Yup.number()
            .required('Freight Charge is required')
            .min(0, 'Freight Charge cannot be negative'),
        otherwise: () => Yup.number().nullable()
      })
    })
  })
});

export const wheelerBillingValidations = Yup.object().shape({
  customerName: Yup.string()
    .required('Customer Name is required')
    .matches(/^\S.*$/, 'Cannot start with a space')
    .min(2, 'Customer Name must be at least 2 characters')
    .max(25, 'Customer Name cannot be greater than 25 charcters')
    .matches(/^[A-Za-z.\s]+$/, 'Name should not contain numbers'),
  customerMobileNumber: Yup.string()
    .required('Mobile Number is required')
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits'),
  driverMobileNumber: Yup.string()
    .required('Mobile Number is required')
    .matches(/^\d{10}$/, 'Mobile Number must be exactly 10 digits'),
  vehicleNumber: Yup.string()
    .required('Vehicle Number is required')
    .min(6, 'Vehicle Number must be at least 6 characters long')
    .max(20, 'Vehicle Number cannot be greater than 20 charcters'),
  vehicleManufacturer: Yup.string().required(
    'Vehicle Manufacturer is required'
  ),
  vehicleModel: Yup.string().required('Vehicle Model is required'),
  vehicleOdometerReading: Yup.number().required(
    'Vehicle Odometer Reading is required'
  ),
  vehicleAverageKmsPerDay: Yup.number().required(
    "Average KM's Per Day is required"
  ),
  gstinNumber: Yup.string().when('useGstInvoice', {
    is: true,
    then: () =>
      Yup.string()
        .required('GSTIN Number is required')
        .min(6, 'GSTIN Number must be at least 6 characters.')
        .max(25, 'GSTIN Number cannot exceed 25 characters')
        .matches(/^\S.*$/, 'Cannot start with a space'),
    otherwise: () => Yup.string()
  }),
  businessName: Yup.string().when('useGstInvoice', {
    is: true,
    then: () =>
      Yup.string()
        .required('Business Name is required')
        .matches(/^\S.*$/, 'Cannot start with a space')
        .min(2, 'Business Name must be at least 2 characters')
        .max(25, 'Business Name cannot be greater than 25 charcters'),
    otherwise: () => Yup.string()
  }),
  services: Yup.object().shape({
    wheelAlignment: Yup.object().shape({
      wheelAlignmentPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    wheelBalancing: Yup.object().shape({
      wheelBalancingPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      }),
      wheelBalancingQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    weightBalancing: Yup.object().shape({
      weightBalancingPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      }),
      weightBalancingQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    tyreChanging: Yup.object().shape({
      tyreChangingPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      }),
      tyreChangingQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    tyreRotation: Yup.object().shape({
      tyreRotationPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      }),
      tyreRotationQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    tyrePuncture: Yup.object().shape({
      tyrePuncturePrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      }),
      tyrePunctureQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    sims: Yup.object().shape({
      simsPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      }),
      simsQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),
    nitrogen: Yup.object().shape({
      nitrogenPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.string()
      }),
      nitrogenQuantity: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Quantity is required')
            .min(0, 'Quantity cannot be negative'),
        otherwise: () => Yup.string()
      })
    }),

    others: Yup.object().shape({
      othersPrice: Yup.number().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.number()
            .required('Price is required')
            .min(0, 'Price cannot be negative'),
        otherwise: () => Yup.number()
      }),
      othersQuantity: Yup.string().when('selected', {
        is: selected => selected === true,
        then: () =>
          Yup.string()
            .required('Description is required')
            .matches(/^\S.*$/, 'Cannot start with a space')
            .min(10, 'Description cannot be less than 10 charcters')
            .max(20, 'Description cannot be greater than 20 charcters'),
        otherwise: () => Yup.string()
      })
    })
  })
});

export const forgotPasswordValidations = Yup.object({
  userEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required')
});

export const verifyOTP = Yup.object({
  userEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  otp: Yup.string()
    .matches(/^\d{6}$/, 'Please enter a valid 6-digit OTP')
    .required('OTP is required')
});

export const newReturnTrackerValidations = Yup.object({
  serialNumber: Yup.string()
    .required('Serial Number is required')
    .matches(
      /^[A-Za-z0-9]+$/,
      'Serial Number should contain only alphanumeric characters'
    )
    .max(13, 'Serial Number cannot exceed 13 characters'),
  manufacturerReferenceId: Yup.string()
    .required('Manufacturer Reference Id is required')
    .matches(/^\S.*$/, 'Cannot start with a space')
    .min(3, 'Reference ID must be at least 3 characters')
    .max(13, 'Reference Id cannot exceed 13 characters'),

  tyreManufacturer: Yup.string().required('Please Select a Tyre Manufacturer'),
  tyreModel: Yup.string().required('Please Select a Tyre Model'),
  customerName: Yup.string()
    .required('Customer Name is required')
    .matches(/^\S.*$/, 'Cannot start with a space')
    .min(6, 'Customer Name must be at least 6 characters')
    .max(25, 'Customer Name cannot be greater than 25 charcters'),
  customerMobileNumber: Yup.string()
    .required('Customer Mobile Number is required')
    .matches(/^\d{10}$/, 'Customer Mobile Number must be exactly 10 digits'),
  returnStatus: Yup.string().required('Please Select a Return Status')
});

export const editReturnTrackerValidations = Yup.object({
  returnStatus: Yup.string().required('Please Select a Return Status')
});

export const sendBulkAlertsValidations = Yup.object({
  vehicleType: Yup.string().required('Vehicle type is required'),
  vehicleManufacturer: Yup.string().required(
    'Vehicle manufacturer is required'
  ),
  vehicleModel: Yup.string().required('Vehicle model is required')
});

export const updateUserProfileValidations = Yup.object({
  userName: Yup.string()
    .required('User Name is required')
    .matches(/^\S.*$/, 'Cannot start with a space')
    .min(6, 'User Name must be at least 6 characters')
    .max(25, 'User Name cannot be greater than 25 charcters'),
  userProfile: Yup.mixed()
    .required('An image is required.')
    .test(
      'fileTypeOrUrl',
      'Unsupported file format. Upload a valid image file.',
      value =>
        typeof value === 'string' ||
        (value && ['image/jpeg', 'image/png'].includes(value.type))
    )
});

export const editUserValidations = Yup.object({
  userName: Yup.string()
    .min(2, 'Employee name must be at least 2 characters')
    .max(25, 'User Name cannot be greater than 25 charcters')
    .matches(/^\S.*$/, 'Cannot start with a space')
    .required('Employee name is required')
    .matches(/^[A-Za-z\s]+$/, 'Name should not contain numbers'),
  userEmail: Yup.string()
    .email('Please enter a valid email address')
    .required('Employee email is required'),
  userRole: Yup.string().required('Please select an employee role'),
  branchNames: Yup.array()
    .min(1, 'Please select at least one branch')
    .required('Branch selection is required'),
  userMobileNumber: Yup.string()
    .matches(/^\d{10}$/, 'Please enter a valid 10-digit mobile number')
    .required('Employee mobile number is required')
});

export const addVehicleValidations = Yup.object({
  vehicleType: Yup.string().required('Vehicle Type is required'),

  vehicleManufacturer: Yup.string()
    .required('Vehicle Manufacturer is required')
    .matches(/^\S.*$/, 'Cannot start with a space')
    .min(2, 'Vehicle Manufacturer must be 2 characters')
    .max(70, 'Vehicle Manufacturer cannot exceed 70 characters'),

  vehicleModel: Yup.string()
    .required('Vehicle Model is required')
    .matches(/^\S.*$/, 'Cannot start with a space')
    .min(2, 'Vehicle Model must be 2 characters')
    .max(70, 'Vehicle Model exceed 70 characters')
});

export const addTyreValidations = Yup.object({
  tyreManufacturer: Yup.string()
    .required('TyreManufacturer is required')
    .matches(/^\S.*$/, 'Cannot start with a space')
    .min(2, 'Tyre Manufacturer must be 2 characters')
    .max(70, 'Tyre Manufacturer cannot exceed 70 characters'),

  tyreModel: Yup.string()
    .required('Tyre Model is required')
    .matches(/^\S.*$/, 'Cannot start with a space')
    .min(2, 'Tyre Model must be 2 characters')
    .max(70, 'Tyre Model exceed 70 characters')
});
