import React from 'react';
import { Button } from 'react-bootstrap';
import Flex from '../common/flex';
import PropTypes from 'prop-types';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PaginationComponent = ({
  prevPage,
  nextPage,
  isPrevDisabled,
  isNextDisabled,
  pageIndex,
  totalItems,
  pageSize,
  onPageChange
}) => {
  const totalPages = Math.ceil(totalItems / pageSize);

  const generatePageNumbers = () => {
    const pages = [];
    let ellipsisIndex = 0;

    const createButton = (key, page, isActive) => (
      <Button
        key={key}
        size="sm"
        style={{
          backgroundColor: isActive ? '#5932EA' : '#f8f9fa',
          color: isActive ? '#fff' : '#000',
          borderColor: 'transparent'
        }}
        onClick={() => onPageChange(page)}
        className="mx-1"
      >
        {key}
      </Button>
    );

    const addEllipsis = () =>
      pages.push(
        <span key={`ellipsis-${ellipsisIndex++}`} className="mx-1">
          ...
        </span>
      );

    const addRangeButtons = (start, end) => {
      for (let i = start; i <= end; i++) {
        pages.push(createButton(i, i - 1, pageIndex + 1 === i));
      }
    };

    if (totalPages > 0) {
      pages.push(createButton(1, 0, pageIndex === 0));
    }

    if (pageIndex > 2) {
      addEllipsis();
    }

    const startPage = Math.max(2, pageIndex);
    const endPage = Math.min(pageIndex + 2, totalPages - 1);
    addRangeButtons(startPage, endPage);

    if (pageIndex < totalPages - 3) {
      addEllipsis();
    }

    if (totalPages > 1) {
      pages.push(
        createButton(totalPages, totalPages - 1, pageIndex + 1 === totalPages)
      );
    }

    return pages;
  };

  return (
    <div className="bottom-padding">
      <Flex className="justify-content-end">
        <Button
          size="sm"
          onClick={prevPage}
          disabled={isPrevDisabled}
          style={{
            backgroundColor: isPrevDisabled ? '#f8f9fa' : '#5932EA',
            color: isPrevDisabled ? '#000' : '#fff',
            borderColor: 'transparent'
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} className="pointer" />
        </Button>

        {generatePageNumbers()}

        <Button
          size="sm"
          className="ms-2"
          onClick={nextPage}
          disabled={isNextDisabled}
          style={{
            backgroundColor: isNextDisabled ? '#f8f9fa' : '#5932EA',
            color: isNextDisabled ? '#000' : '#fff',
            borderColor: 'transparent'
          }}
        >
          <FontAwesomeIcon icon={faAngleRight} className="pointer" />
        </Button>
      </Flex>
    </div>
  );
};

PaginationComponent.propTypes = {
  prevPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  isPrevDisabled: PropTypes.bool.isRequired,
  isNextDisabled: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};
