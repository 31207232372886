import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import Loader from '../../core/loader/loader';
import Flex from '../../common/flex';
import Profile from '../../common/profile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import DataTransferService from '../../core/service/dataTransferService';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import { sendBulkAlertsValidations } from '../../validations/forms';
import { toast } from 'react-toastify';
import UserService from '../../core/service/userService';
import { SelectField } from '../../validations/inputFields';
import { getVehicleManufacturers, vehicleModel } from '../../common/commonApis';
import { toPascalCase } from '../../common/shared';

const SendBulkAlerts = () => {
  const messageContainerStyle = {
    backgroundColor: '#E5FFD8',
    borderRadius: '0.625rem',
    padding: '1rem',
    width: '24.5rem'
  };

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const initialValues = {
    vehicleType: '',
    vehicleManufacturer: '',
    vehicleModel: ''
  };

  const dataTransferService = new DataTransferService();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const userService = new UserService();
  const [vehicleManfacturerResponse, setVehicleManfacturerResponse] = useState(
    []
  );
  const [responseVehicleModel, setResponseVehicleModel] = useState([]);
  const [vehicleType, setVehicleType] = useState('');
  const [disableFields, setDisableFields] = useState(true);

  const fetchCount = vehicleDTO => {
    setLoading(true);
    userService
      .fetchCountToSendBulkAlerts(organisationName, branchName, vehicleDTO)
      .then(response => {
        setLoading(false);
        if (response?.data?.responseCode === 200) {
          const alertCount = response.data.responseBody;
          setAlertMessage(() => {
            const manufacturer = vehicleDTO.vehicleManufacturer;
            const model = vehicleDTO.vehicleModel;
            return `${alertCount} Results Found For ${manufacturer} ${model}`;
          });
          toast.success('Bulk alerts count fetched successfully!');
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('fetchCountToSendBulkAlerts', error.response);
      });
  };

  const handleSearch = (values, { setSubmitting }) => {
    const formattedData = {
      vehicleType: values.vehicleType?.toUpperCase(),
      vehicleManufacturer: values.vehicleManufacturer.toUpperCase(),
      vehicleModel: values.vehicleModel.toUpperCase()
    };

    fetchCount(formattedData);
    setSubmitting(false);
  };

  const handleSendAlerts = vehicleDTO => {
    setLoading(true);
    userService
      .sendBulkAlertsBasedOnVehicle(organisationName, branchName, vehicleDTO)
      .then(response => {
        setLoading(false);
        if (response.data.responseCode === 200) {
          setLoading(false);
          toast.success('Reminder sent successfully!', { theme: 'colored' });
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('sendBulkAlerts', error.response);
      });
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}
      <Flex className="d-flex justify-content-end align-items-center mt-15">
        <Profile />
      </Flex>
      <div className="alerts-bg-container p-4">
        <div className="row">
          <div className="col-12">
            <p className="bulk-alerts-heading mb-5">Bulk Alerts</p>
            <div>
              <p className="fs-6 bulk-alerts-heading mb-3">
                Select the fields to send alerts
              </p>
              <Formik
                initialValues={initialValues}
                validationSchema={sendBulkAlertsValidations}
                onSubmit={(values, { setSubmitting }) => {
                  handleSearch(values, { setSubmitting });
                }}
              >
                {formik => (
                  <Form>
                    <div className="row">
                      <div className="col-5">
                        <SelectField
                          label="Vehicle Type"
                          id="vehicleType"
                          name="vehicleType"
                          options={['CAR', 'TRUCK']}
                          className="form-select"
                          onChange={e => {
                            const vehicleType = e.target.value;
                            formik.setFieldValue('vehicleType', vehicleType);
                            setVehicleType(vehicleType);
                            setDisableFields(false);
                            getVehicleManufacturers(vehicleType, {
                              setLoading,
                              setVehicleManfacturerResponse
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-5">
                        <SelectField
                          label="Vehicle Manufacturer"
                          id="vehicleManufacturer"
                          name="vehicleManufacturer"
                          options={vehicleManfacturerResponse}
                          className="form-select"
                          onChange={e => {
                            const selectedManufacturer = e.target.value;
                            selectedManufacturer.toUpperCase();
                            formik.setFieldValue(
                              'vehicleManufacturer',
                              selectedManufacturer
                            );
                            vehicleModel(vehicleType, selectedManufacturer, {
                              setLoading,
                              setResponseVehicleModel
                            });
                          }}
                          disabled={disableFields}
                        />
                      </div>
                      <div className="col-5">
                        <SelectField
                          label="Vehicle Model"
                          id="vehicleModel"
                          name="vehicleModel"
                          options={
                            responseVehicleModel.length > 0
                              ? responseVehicleModel.map(model =>
                                  toPascalCase(model.label)
                                )
                              : [formik.values.vehicleModel]
                          }
                          className="form-select"
                          disabled={
                            !formik.values.vehicleManufacturer || disableFields
                          }
                        />
                      </div>
                      <div className="col-2 d-flex justify-content-end button-search">
                        <button
                          type="submit"
                          className="button-indigo button-indigo-height"
                        >
                          Search
                        </button>
                      </div>
                      <h6 className="strong-blue">{alertMessage}</h6>
                    </div>
                    <div className="mt-5">
                      <h6>Message Preview:</h6>
                      <div className="mt-4" style={messageContainerStyle}>
                        <h6>Greetings from {organisationName}</h6>
                        <h6 className="mt-4">Dear Customer,</h6>
                        <p className="fs-14 fw-500">
                          We’re reaching out from {organisationName} with an
                          update on items suited for your{' '}
                          {formik.values.vehicleType} Model:{' '}
                          {formik.values.vehicleModel}, Manufacturer:{' '}
                          {formik.values.vehicleManufacturer}. We have limited
                          stock available for these items, so please visit us at
                          your convenience if you’d like to explore your
                          options.
                        </p>
                        <h6 className="mt-4">Best regards,</h6>
                        <h6>{organisationName}</h6>
                      </div>
                    </div>
                    <div className="pt-4">
                      <button
                        type="button"
                        className="p-2 button-styling w-25"
                        onClick={() => {
                          const count = parseInt(
                            alertMessage.split(' ')[0],
                            10
                          );

                          if (count === 0) {
                            toast.error('No results were found.', {
                              theme: 'colored'
                            });
                            return;
                          }

                          if (
                            !formik.values.vehicleType ||
                            !formik.values.vehicleManufacturer ||
                            !formik.values.vehicleModel
                          ) {
                            toast.error(
                              'Please select the filtration fields to send a reminder!',
                              {
                                theme: 'colored'
                              }
                            );
                          } else {
                            const formattedData = {
                              vehicleType:
                                formik.values.vehicleType?.toUpperCase(),
                              vehicleManufacturer:
                                formik.values.vehicleManufacturer?.toUpperCase(),
                              vehicleModel:
                                formik.values.vehicleModel?.toUpperCase()
                            };
                            handleSendAlerts(formattedData);
                          }
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPaperPlane}
                          className="me-3 rotate-up"
                        />
                        Send Reminder Now
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendBulkAlerts;
