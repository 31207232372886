import React, { useCallback, useEffect, useState } from 'react';
import Profile from '../../common/profile';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import UserService from '../../core/service/userService';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import Loader from '../../core/loader/loader';
import DataTransferService from '../../core/service/dataTransferService';
import SearchInput from '../../common/search';
import { Back } from '../../common/back';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const ManageBranches = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [branchesData, setBranchesData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const location = useLocation();
  const { organisationName } = useParams();
  const [loading, setLoading] = useState(true);
  const dataTransferService = new DataTransferService();
  const [editBranch, setEditBranch] = useState(null);
  const [editedData, setEditedData] = useState({});

  const fetchBranches = useCallback(organisationName => {
    setLoading(true);
    const userService = new UserService();
    userService
      .fetchBranches(organisationName)
      .then(response => {
        if (response.data.responseCode === 200) {
          setBranchesData(response.data.responseBody);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('branches-by', error.response);
      });
  }, []);

  useEffect(() => {
    if (organisationName) {
      fetchBranches(organisationName);
    }
  }, [fetchBranches, organisationName]);

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const filteredBranches = branchesData.filter(
    branch =>
      branch.branchName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      branch.branchAddress.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const navigate = useNavigate();
  const navigateToCreateBranches = () => {
    if (location.pathname.startsWith('/manage-branches/')) {
      const storedData = dataTransferService.getBranchDTO();
      const organisationDTO = storedData
        ? JSON.parse(storedData)
        : { firstTime: true };
      organisationDTO.firstTime = false;
      organisationDTO.numLocations = filteredBranches.length;
      organisationDTO.organisationName = organisationName;
      organisationDTO.organisationProfile = branchesData[0].organisationProfile;
      organisationDTO.branchDTOList = [];
      dataTransferService.setBranchDTO(JSON.stringify(organisationDTO));
      navigate('/create-branches');
    }
  };

  const handleEditClick = branch => {
    setEditBranch(branch.branchName);
    setEditedData(branch);
  };

  const handleInputChange = (event, field) => {
    const { value } = event.target;
    setEditedData(prev => ({ ...prev, [field]: value }));
  };

  const updateOrgAndBran = (editBranch, editedData) => {
    const orgAndBranData = JSON.parse(localStorage.getItem('orgAndBran'));

    if (!orgAndBranData || !Array.isArray(orgAndBranData)) {
      console.error('Invalid orgAndBran data in localStorage.');
      return;
    }

    const updatedOrgAndBranData = orgAndBranData.map(org => {
      if (org.branches.includes(editBranch)) {
        const updatedBranches = org.branches.map(branch =>
          branch === editBranch ? editedData : branch
        );

        return {
          ...org,
          branches: updatedBranches
        };
      }
      return org;
    });

    localStorage.setItem('orgAndBran', JSON.stringify(updatedOrgAndBranData));
    window.location.reload();
  };

  const saveEdits = () => {
    if (
      !editedData.branchName?.trim() ||
      !editedData.branchAddress?.trim() ||
      !editedData.branchMobileNumber?.trim() ||
      !editedData.storeAddressLink?.trim()
    ) {
      toast.info(
        'All fields are required. Please fill in the missing information.',
        { theme: 'colored' }
      );
      return;
    }

    const originalBranch = branchesData.find(
      branch => branch.branchName === editBranch
    );

    const changedData = {};
    if (originalBranch.branchName !== editedData.branchName) {
      changedData.branchName = editedData.branchName;
    }

    if (originalBranch.branchAddress !== editedData.branchAddress) {
      changedData.branchAddress = editedData.branchAddress;
    }

    if (originalBranch.branchMobileNumber !== editedData.branchMobileNumber) {
      changedData.branchMobileNumber = editedData.branchMobileNumber;
    }

    if (originalBranch.storeAddressLink !== editedData.storeAddressLink) {
      changedData.storeAddressLink = editedData.storeAddressLink;
    }

    if (Object.keys(changedData).length === 0) {
      toast.info('No changes detected.', { theme: 'colored' });
      cancelEdits();
      return;
    }

    const updatedBranches = branchesData.map(branch =>
      branch.branchName === editBranch
        ? {
            ...branch,
            ...changedData,
            storeAddressLink:
              changedData.storeAddressLink || branch.storeAddressLink
          }
        : branch
    );
    setBranchesData(updatedBranches);
    setEditBranch(null);

    const userService = new UserService();
    setLoading(true);
    userService
      .editBranch(organisationName, editBranch, changedData)
      .then(response => {
        if (response.data.responseCode === 201) {
          const selectedBranch = dataTransferService.getSelectedBranch();
          if (selectedBranch === editBranch) {
            dataTransferService.setSelectedBranch(editedData.branchName);
          }

          const listOfBranches = dataTransferService.getListOfBranches();
          if (listOfBranches) {
            const branchesArray = listOfBranches.split(',');
            if (branchesArray.includes(editBranch)) {
              const updatedBranchesArray = branchesArray.map(branch =>
                branch === editBranch ? editedData.branchName : branch
              );
              localStorage.setItem(
                'listOfBranches',
                updatedBranchesArray.join(',')
              );
            }
          }

          updateOrgAndBran(editBranch, editedData.branchName);
          setLoading(false);
          toast.success(response.data.responseMessage, {
            theme: 'colored'
          });
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('update-branch', error.response);
      });
  };

  const cancelEdits = () => {
    setEditBranch(null);
    setEditedData({});
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <div className="d-flex justify-content-between align-items-center pt-1">
        <p className="fs-4 fw-500">Settings</p>
        <Profile />
      </div>

      <div className="custom-container mt-36 p-4">
        <div className="d-flex align-items-center">
          <Back />
          <p className="fs-4 fw-600 ps-3 w-75 me-5">
            {organisationName || 'Organisation Name'}
          </p>
          <button
            type="button"
            className="btn btn-outline custom-btn-outline ms-2"
            onClick={navigateToCreateBranches}
          >
            + Add location
          </button>
        </div>

        <div className="pt-4">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search by location, name"
            id="manage-branches-search-input"
          />
        </div>

        <div className="pt-40">
          <p className="pt-3 fs-6 fw-600">Your locations</p>
        </div>

        {filteredBranches.length > 0 &&
          filteredBranches.map(branch => (
            <div key={branch.branchName}>
              {editBranch === branch.branchName ? (
                <div className="pt-32 d-flex align-items-center justify-content-center">
                  <div className="d-flex flex-column pe-67 ps-4 w-100">
                    <input
                      className="form-control"
                      value={editedData.branchName || ''}
                      onChange={e => handleInputChange(e, 'branchName')}
                      placeholder="Branch Name"
                    />
                    <input
                      className="form-control mt-2"
                      value={editedData.branchAddress || ''}
                      onChange={e => handleInputChange(e, 'branchAddress')}
                      placeholder="Branch Address"
                    />
                    <input
                      className="form-control mt-2"
                      value={editedData.branchMobileNumber || ''}
                      onChange={e => handleInputChange(e, 'branchMobileNumber')}
                      placeholder="Branch Phone"
                    />
                    <input
                      className="form-control mt-2"
                      value={editedData.storeAddressLink || ''}
                      onChange={e => handleInputChange(e, 'storeAddressLink')}
                      placeholder="Store Link"
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-primary me-2"
                      onClick={saveEdits}
                    >
                      <FontAwesomeIcon icon={faSave} className="me-1" />
                      Save
                    </button>
                    <button
                      className="btn btn-secondary mt-2"
                      onClick={cancelEdits}
                    >
                      <FontAwesomeIcon icon={faTimes} className="me-1" />
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="pt-32 d-flex align-items-center justify-content-center">
                  <img
                    src={
                      dataTransferService.getBloburl() +
                      branch.organisationProfile
                    }
                    alt="user-profile-icon"
                    width={80}
                    height={80}
                    style={{
                      objectFit: 'cover'
                    }}
                  />
                  <div className="d-flex flex-column pe-67 ps-4 w-100">
                    <span className="fs-6 fw-500">
                      Name: {branch.branchName}
                    </span>
                    <span className="fs-15 fw-500">
                      Address: {branch.branchAddress}
                    </span>
                    <span className="fs-14 fw-500">
                      Ph: {branch.branchMobileNumber}
                    </span>
                    <span className="fs-14 fw-500">
                      Store Link: {branch.storeAddressLink || 'N/A'}
                    </span>
                  </div>
                  <div>
                    <button
                      className="btn-no-style me-2"
                      onClick={() => handleEditClick(branch)}
                    >
                      <FontAwesomeIcon icon={faEdit} className="me-1" /> Edit
                    </button>
                  </div>
                </div>
              )}
              <hr />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ManageBranches;
