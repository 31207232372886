import React, { useState, useEffect, useCallback } from 'react';
import DataTransferService from '../../core/service/dataTransferService';
import { Table } from 'react-bootstrap';
import ServiceRequest from '../../core/service/serviceRequest';
import Loader from '../../core/loader/loader';
import { PaginationComponent } from '../../common/pagination';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Flex from '../../common/flex';
import Profile from '../../common/profile';
import SearchInput from '../../common/search';
import { Back } from '../../common/back';
import { numberFormatter } from '../../common/numberFormatter';
import Calendar from '../../common/calender';
import { formatLocalDate } from '../../common/shared';

const ShowCreditHistory = () => {
  const [isFocused, setIsFocused] = useState(false);
  const dataTransferService = new DataTransferService();
  const [searchTerm, setSearchTerm] = useState('');
  const [creditData, setCreditData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize] = useState(10);
  const navigate = useNavigate();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const [showCalendar, setShowCalendar] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState('Calender');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { sDate, eDate } = useParams();
  const [searchPageIndex, setSearchPageIndex] = useState(0);

  const fetchCreditData = useCallback(
    (page, size, startDate, endDate) => {
      setLoading(true);
      setErrorMessage('');
      const serviceRequest = new ServiceRequest();
      serviceRequest
        .paymentStatus(
          page,
          size,
          organisationName,
          branchName,
          formatLocalDate(startDate),
          formatLocalDate(endDate)
        )
        .then(response => {
          if (response.data.responseCode === 200) {
            setCreditData(response.data.responseBody.settledInvoices);
            setTotalItems(response.data.responseBody.totalItems);
            setSearchPageIndex(0);
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          handleErrorResponse('fetchCreditData', error.response);
          setErrorMessage(error.response.data.responseMessage);
          setTotalItems(0);
          setSearchPageIndex(0);
        });
    },
    [branchName, organisationName]
  );

  const searchCreditData = useCallback(() => {
    setLoading(true);
    setErrorMessage('');
    const serviceRequest = new ServiceRequest();
    serviceRequest
      .searchCreditDetails(
        searchTerm,
        searchPageIndex,
        pageSize,
        branchName,
        organisationName
      )
      .then(response => {
        setLoading(false);
        if (response.data.responseCode === 200) {
          setCreditData(response.data.responseBody.services);
          setTotalItems(response.data.responseBody.TotalItems);
          setPageIndex(0);
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('searchCreditData', error.response);
        setErrorMessage(error.response.data.responseMessage);
        setTotalItems(0);
        setPageIndex(0);
      });
  }, [searchTerm, searchPageIndex, pageSize, branchName, organisationName]);

  useEffect(() => {
    if (searchTerm) {
      searchCreditData();
    }
  }, [searchTerm, searchCreditData]);

  useEffect(() => {
    if (!searchTerm && sDate && eDate) {
      const parsedStartDate = new Date(sDate);
      const parsedEndDate = new Date(eDate);
      setStartDate(parsedStartDate);
      setEndDate(parsedEndDate);
      fetchCreditData(pageIndex, pageSize, parsedStartDate, parsedEndDate);
    }
  }, [sDate, eDate, searchTerm, fetchCreditData, pageSize, pageIndex]);

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
    setPageIndex(0);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setPageIndex(0);
  };

  const handleNavigate = () => {
    navigate('/select-vehicle');
  };

  const prevPage = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1);
  };

  const searchPrevPage = () => {
    if (searchPageIndex > 0) setSearchPageIndex(searchPageIndex - 1);
  };

  const nextPage = () => {
    if (pageIndex < Math.ceil(totalItems / pageSize) - 1)
      setPageIndex(pageIndex + 1);
  };

  const searchNextPage = () => {
    if (searchPageIndex < Math.ceil(totalItems / pageSize) - 1)
      setSearchPageIndex(searchPageIndex + 1);
  };

  const onPageChange = newPageIndex => {
    setPageIndex(newPageIndex);
  };

  const onSearchPageChange = newPageIndex => {
    setSearchPageIndex(newPageIndex);
  };

  const handleDateChange = update => {
    const [start, end] = update;
    setStartDate(start);
    setEndDate(end);

    if (end) {
      const currentEndDate = new Date(end);
      currentEndDate.setHours(23, 59, 59, 999);
      setEndDate(currentEndDate);

      const formattedStart = start?.toLocaleDateString();
      const formattedEnd = currentEndDate.toLocaleDateString();
      navigate(
        `/show-credit-history/${start.toISOString()}/${currentEndDate.toISOString()}`
      );
      setDropdownLabel(`${formattedStart} - ${formattedEnd}`);
      setShowCalendar(false);
      setPageIndex(0);
    } else {
      setEndDate(null);
    }
  };

  const handleSortClick = () => {
    setShowCalendar(false);
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <Flex className="d-flex justify-content-end align-items-center mt-15">
        <Profile />
      </Flex>

      <div className="white-container mt-5 services-card-radius p-4">
        <Flex className="align-items-center justify-content-start">
          <Back />
          <p className="padding-heading fw-600 ps-2">Credit Services History</p>
        </Flex>

        <Flex className="pt-3 align-items-center justify-content-end">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search"
            className="w-25"
            id="show-credit-history-search-input"
          />

          {!searchTerm && (
            <div className="d-flex justify-content-end align-items-center">
              <div className="dropdown-select d-flex align-items-center">
                <button
                  onClick={handleSortClick}
                  className="fs-14 fw-500 grayish-blue pe-2 text-nowrap btn-no-style"
                >
                  Sort by:
                </button>
                <Calendar
                  startDate={formatLocalDate(startDate)}
                  endDate={formatLocalDate(endDate)}
                  onDateChange={handleDateChange}
                  dropdownLabel={dropdownLabel}
                  showCalendar={showCalendar}
                  setShowCalendar={setShowCalendar}
                />
              </div>
            </div>
          )}
        </Flex>

        <div className="d-flex justify-content-end me-2 mt-3">
          <button
            className="btn btn-outline custom-btn-outline new-service-button"
            onClick={handleNavigate}
          >
            + New Service
          </button>
        </div>

        <div className="table-responsive">
          <Table hover>
            <thead>
              <tr>
                <th className="services-headers">Vehicle Number</th>
                <th className="services-headers">Invoice Number</th>
                <th className="services-headers">Customer Name</th>
                <th className="services-headers">Category</th>
                <th className="services-headers">Amount</th>
                <th className="services-headers">Payment Type</th>
                <th className="services-headers">Invoice</th>
              </tr>
            </thead>

            <tbody>
              {errorMessage ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    <div className="text-center text-danger">
                      {errorMessage}
                    </div>
                  </td>
                </tr>
              ) : (
                creditData?.map(service => (
                  <tr key={service.invoiceSerialNumber}>
                    <td className="p-3">{service.vehicleNumber}</td>
                    <td className="p-3">{service.invoiceSerialNumber}</td>
                    <td className="p-3">{service.customerName}</td>
                    <td className="p-3">{service.vehicleType}</td>
                    <td className="p-3">{service.amount.toFixed(0)}</td>
                    <td className="p-3">{service.paymentType}</td>
                    <td className="p-3">
                      <Link
                        to={`/invoice/${service.invoiceSerialNumber}`}
                        style={{
                          color: '#127BFF',
                          textDecoration: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-4 fs-14 fw-500 grayish-blue">
          {!searchTerm ? (
            <span>
              {totalItems > 0
                ? `Showing ${pageIndex * pageSize + 1} to ${Math.min(
                    (pageIndex + 1) * pageSize,
                    totalItems
                  )} of ${numberFormatter(totalItems)} entries`
                : 'No entries available'}
            </span>
          ) : (
            <span>
              {totalItems > 0
                ? `Showing ${searchPageIndex * pageSize + 1} to ${Math.min(
                    (searchPageIndex + 1) * pageSize,
                    totalItems
                  )} of ${numberFormatter(totalItems)} entries`
                : 'No entries available'}
            </span>
          )}
          {!searchTerm ? (
            <PaginationComponent
              prevPage={prevPage}
              nextPage={nextPage}
              isNextDisabled={pageIndex >= Math.ceil(totalItems / pageSize) - 1}
              isPrevDisabled={pageIndex === 0}
              pageIndex={pageIndex}
              totalItems={totalItems}
              pageSize={pageSize}
              onPageChange={onPageChange}
            />
          ) : (
            <PaginationComponent
              prevPage={searchPrevPage}
              nextPage={searchNextPage}
              isNextDisabled={
                searchPageIndex >= Math.ceil(totalItems / pageSize) - 1
              }
              isPrevDisabled={searchPageIndex === 0}
              pageIndex={searchPageIndex}
              totalItems={totalItems}
              pageSize={pageSize}
              onPageChange={onSearchPageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowCreditHistory;
