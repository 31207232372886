import React, { useState, useEffect, useCallback } from 'react';
import { Table } from 'react-bootstrap';
import Flex from '../../common/flex';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Profile from '../../common/profile';
import ReturnTrackerRequest from '../../core/service/returnTrackerRequest';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import DataTransferService from '../../core/service/dataTransferService';
import { PaginationComponent } from '../../common/pagination';
import Loader from '../../core/loader/loader';
import SearchInput from '../../common/search';
import Calendar from '../../common/calender';

import { Back } from '../../common/back';
import SubtleBadge, {
  statusByColor,
  toPascalWord
} from '../../common/subtleBadge';
import { numberFormatter } from '../../common/numberFormatter';
import { formatLocalDate } from '../../common/shared';

const ViewAllReturnRequests = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [searchPageIndex, setSearchPageIndex] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize] = useState(10);
  const [returnRequests, setReturnRequests] = useState([]);
  const dataTransferService = new DataTransferService();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const [showCalendar, setShowCalendar] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState('Calender');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { sDate, eDate } = useParams();
  const navigate = useNavigate();

  const fetchReturnServices = useCallback(
    (startDate, endDate) => {
      setLoading(true);
      const returnTrackerRequest = new ReturnTrackerRequest();
      returnTrackerRequest
        .fetchBranchDetailsWithReturnCounts(
          organisationName,
          branchName,
          formatLocalDate(startDate),
          formatLocalDate(endDate),
          pageIndex,
          pageSize
        )
        .then(response => {
          setLoading(false);
          if (response?.data?.responseCode === 200) {
            setErrorMessage('');
            setReturnRequests(response.data.responseBody.allReturnRequests);
            setTotalElements(response.data.responseBody.totalItems);
            setSearchPageIndex(0);
          }
        })
        .catch(error => {
          setLoading(false);
          handleErrorResponse('fetchReturnServices', error.response);
          setErrorMessage(error.response?.data?.responseMessage);
          setTotalElements(0);
          setSearchPageIndex(0);
        });
    },
    [pageIndex, pageSize, organisationName, branchName]
  );

  const searchReturnRequests = useCallback(() => {
    setLoading(true);
    setErrorMessage('');
    const returnTrackerRequest = new ReturnTrackerRequest();
    returnTrackerRequest
      .searchReturnsBySerialNumber(
        organisationName,
        branchName,
        searchTerm,
        searchPageIndex,
        pageSize
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setErrorMessage('');
          setLoading(false);
          setReturnRequests(response.data.responseBody.allReturnRequests);
          setTotalElements(response.data.responseBody.totalItems);
          setPageIndex(0);
        }
      })
      .catch(error => {
        setPageIndex(0);
        setLoading(false);
        handleErrorResponse('searchReturnRequests', error.response);
        setErrorMessage(error.response?.data?.responseMessage);
        setTotalElements(0);
      });
  }, [organisationName, branchName, searchTerm, searchPageIndex, pageSize]);

  useEffect(() => {
    if (searchTerm) {
      searchReturnRequests(searchPageIndex, pageSize);
    }
  }, [searchTerm, searchPageIndex, searchReturnRequests, pageSize]);

  useEffect(() => {
    if (!searchTerm && sDate && eDate) {
      setStartDate(sDate);
      setEndDate(eDate);
      fetchReturnServices(sDate, eDate);
    }
  }, [sDate, eDate, searchTerm, fetchReturnServices]);

  const handleDateChange = update => {
    const [start, end] = update;
    setStartDate(start);
    setEndDate(end);
    setPageIndex(0);
    setSearchPageIndex(0);
    if (end) {
      const currentEndDate = new Date(end);
      currentEndDate.setHours(23, 59, 59, 999);
      setEndDate(currentEndDate);
      const formattedStart = start?.toLocaleDateString();
      const formattedEnd = currentEndDate.toLocaleDateString();
      navigate(
        `/view-all-return-requests/${start.toISOString()}/${currentEndDate.toISOString()}`
      );
      setDropdownLabel(`${formattedStart} - ${formattedEnd}`);
      setShowCalendar(false);
      setPageIndex(0);
    } else {
      setEndDate(null);
    }
  };

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setPageIndex(0);
  };

  const prevPage = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1);
  };

  const searchPrevPage = () => {
    if (searchPageIndex > 0) setSearchPageIndex(searchPageIndex - 1);
  };

  const nextPage = () => {
    if (pageIndex < Math.ceil(totalElements / pageSize) - 1)
      setPageIndex(pageIndex + 1);
  };

  const searchNextPage = () => {
    if (searchPageIndex < Math.ceil(totalElements / pageSize) - 1)
      setSearchPageIndex(searchPageIndex + 1);
  };

  const onPageChange = newPageIndex => {
    setPageIndex(newPageIndex);
  };

  const onSearchPageChange = newPageIndex => {
    setSearchPageIndex(newPageIndex);
  };

  const handleSortClick = () => {
    setShowCalendar(false);
  };

  const formatDate = dateString => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <div className="d-flex justify-content-end mt-15">
        <Profile />
      </div>

      <div className="card-all-return-requests p-4 mt-5">
        <Flex className="align-items-center justify-content-start">
          <Back />
          <p className="padding-heading fw-600 ps-2">All Return Requests</p>
        </Flex>
        <Flex className="mt-3 mb-3 align-items-center justify-content-end">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search"
            className="w-25"
            id="viewall-return-requests-search-input"
          />
          {!searchTerm && (
            <div className="d-flex justify-content-end align-items-center">
              <div className="dropdown-select d-flex align-items-center">
                <button
                  onClick={handleSortClick}
                  className="fs-14 fw-500 grayish-blue pe-2 text-nowrap btn-no-style"
                >
                  Sort by:
                </button>
                <Calendar
                  startDate={formatLocalDate(startDate)}
                  endDate={formatLocalDate(endDate)}
                  onDateChange={handleDateChange}
                  dropdownLabel={dropdownLabel}
                  showCalendar={showCalendar}
                  setShowCalendar={setShowCalendar}
                />
              </div>
            </div>
          )}
        </Flex>
        <Table>
          <thead>
            <tr>
              <th>Serial Number</th>
              <th>Company</th>
              <th>Model Name</th>
              <th>Customer name</th>
              <th>Created</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {errorMessage ? (
              <tr>
                <td colSpan="7" className="text-center">
                  <div className="text-center text-danger">{errorMessage}</div>
                </td>
              </tr>
            ) : (
              returnRequests.map(request => (
                <tr key={request.serialNumber}>
                  <td>{request.serialNumber}</td>
                  <td className="ps-3">
                    {request.company === 'OTHER'
                      ? request.customTyreManufacturer
                      : request.tyreManufacturer}
                  </td>
                  <td className="limited-text ps-3">
                    {request.tyreModel === 'OTHER'
                      ? request.customTyreModel
                      : request.tyreModel}
                  </td>
                  <td>
                    <Link
                      to={`/customer-returns-counts/${request.customerName}`}
                      style={{
                        color: '#127BFF',
                        textDecoration: 'none',
                        cursor: 'pointer'
                      }}
                    >
                      {request.customerName}
                    </Link>
                  </td>
                  <td>{formatDate(request.created.createdTime)}</td>
                  <td>
                    <SubtleBadge
                      bg={statusByColor(request.returnStatus)}
                      className="fs-14 fw-500"
                    >
                      {toPascalWord(request.returnStatus)}
                    </SubtleBadge>
                  </td>
                  <td>
                    <Link
                      to={`/edit-return-tracker/${request.serialNumber}`}
                      style={{
                        color: '#127BFF',
                        textDecoration: 'none',
                        cursor: 'pointer'
                      }}
                    >
                      Edit
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>

        <Flex className="mt-4 justify-content-between align-items-center fs-14 fw-500 grayish-blue">
          {!searchTerm ? (
            <span>
              {totalElements > 0
                ? `Showing ${pageIndex * pageSize + 1} to ${Math.min(
                    (pageIndex + 1) * pageSize,
                    totalElements
                  )} of ${numberFormatter(totalElements)} entries`
                : 'No entries available'}
            </span>
          ) : (
            <span>
              {totalElements > 0
                ? `Showing ${searchPageIndex * pageSize + 1} to ${Math.min(
                    (searchPageIndex + 1) * pageSize,
                    totalElements
                  )} of ${numberFormatter(totalElements)} entries`
                : 'No entries available'}
            </span>
          )}

          {!searchTerm ? (
            <PaginationComponent
              prevPage={prevPage}
              nextPage={nextPage}
              isNextDisabled={
                pageIndex >= Math.ceil(totalElements / pageSize) - 1
              }
              isPrevDisabled={pageIndex === 0}
              pageIndex={pageIndex}
              totalItems={totalElements}
              pageSize={pageSize}
              onPageChange={onPageChange}
            />
          ) : (
            <PaginationComponent
              prevPage={searchPrevPage}
              nextPage={searchNextPage}
              isNextDisabled={
                searchPageIndex >= Math.ceil(totalElements / pageSize) - 1
              }
              isPrevDisabled={searchPageIndex === 0}
              pageIndex={searchPageIndex}
              totalItems={totalElements}
              pageSize={pageSize}
              onPageChange={onSearchPageChange}
            />
          )}
        </Flex>
      </div>
    </div>
  );
};

export default ViewAllReturnRequests;
