import { React, useState } from 'react';
import { Formik, Form } from 'formik';
import Loader from '../../core/loader/loader';
import { InputField, SelectField } from '../../validations/inputFields';
import { addVehicleValidations } from '../../validations/forms';
import UserService from '../../core/service/userService';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import { toast } from 'react-toastify';

const AddVehicleInfo = () => {
  const [loading, setLoading] = useState(false);
  const initialValues = {
    vehicleType: '',
    vehicleManufacturer: '',
    vehicleModel: ''
  };

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true);
    const { vehicleManufacturer, vehicleModel, vehicleType } = values;
    const manufacturer = vehicleManufacturer.trim().replace(/\s+/g, ' ');
    const model = vehicleModel.trim().replace(/\s+/g, ' ');

    const userService = new UserService();
    userService
      .addVehicleInfo(manufacturer, model, vehicleType)
      .then(response => {
        if (response.data.responseCode === 201) {
          setLoading(false);
          toast.success(response.data.responseMessage);
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('handleSubmit', error.response);
      })
      .finally(() => {
        resetForm();
      });
  };
  return (
    <>
      {loading && <Loader loading={loading} />}

      <div className="p-3 mt-3">
        <h1 className="vehicles-heading">Add Vehicle Details</h1>
      </div>
      <div className="p-3">
        <h2 className="sub-heading">Vehicle Details</h2>
      </div>

      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={addVehicleValidations}
        onSubmit={(values, { resetForm }) =>
          handleSubmit(values, { resetForm })
        }
      >
        {formik => (
          <Form>
            <>
              <div className="row p-3">
                <div className="col-md-6 ">
                  <SelectField
                    label="Vehicle Type"
                    id="vehicleType"
                    name="vehicleType"
                    options={['CAR', 'TRUCK']}
                    className="form-select vehicle-input"
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Vehicle Company"
                    name="vehicleManufacturer"
                    type="text"
                    placeholder="Enter Vehicle Company"
                    className="form-control vehicle-input"
                  />
                </div>
              </div>
              <div className="row p-3">
                <div className="col-md-6">
                  <InputField
                    label="Vehicle Model"
                    name="vehicleModel"
                    type="text"
                    placeholder="Enter Vehicle Model"
                    className="form-control vehicle-input"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end m-4">
                <button className="vehicle-button">Add Vehicle</button>
              </div>
            </>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default AddVehicleInfo;
