import React, { useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DataTransferService from '../core/service/dataTransferService';

const Calendar = ({
  startDate,
  endDate,
  onDateChange,
  dropdownLabel,
  showCalendar,
  setShowCalendar
}) => {
  const newStartDate = startDate ? new Date(startDate) : null;
  const newEndDate = endDate ? new Date(endDate) : null;
  const calendarRef = useRef(null);
  const dataTransferService = useMemo(() => new DataTransferService(), []);
  const minDate = dataTransferService.getMinDate();

  const today = useMemo(() => new Date(), []);
  const yesterday = useMemo(() => {
    const date = new Date(today);
    date.setDate(today.getDate() - 1);
    return date;
  }, [today]);

  const adjustDateToMinDate = useCallback(
    date => {
      return date < minDate ? minDate : date;
    },
    [minDate]
  );

  const dateRanges = useMemo(
    () => ({
      today: [today, today],
      yesterday: [yesterday, yesterday],
      thisWeek: (() => {
        const start = new Date(today);
        start.setDate(today.getDate() - today.getDay());
        return [start, today];
      })(),
      lastWeek: (() => {
        const start = new Date(today);
        const end = new Date(today);
        start.setDate(today.getDate() - today.getDay() - 7);
        end.setDate(today.getDate() - today.getDay() - 1);
        return [start, end];
      })(),
      thisMonth: [new Date(today.getFullYear(), today.getMonth(), 1), today],
      lastMonth: [
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        new Date(today.getFullYear(), today.getMonth(), 0)
      ],
      thisYear: [new Date(today.getFullYear(), 0, 1), today],
      lastYear: (() => {
        const start = new Date(today.getFullYear() - 1, 0, 1);
        const end = new Date(today.getFullYear() - 1, 11, 31);
        const adjustedStart = adjustDateToMinDate(start);
        const adjustedEnd = end > today ? today : end;

        return [adjustedStart, adjustedEnd];
      })()
    }),
    [today, yesterday, adjustDateToMinDate]
  );

  const formatDate = useCallback(date => {
    if (!date) return '';
    const validDate = new Date(date);
    if (isNaN(validDate)) return '';
    const day = validDate.getDate().toString().padStart(2, '0');
    const month = (validDate.getMonth() + 1).toString().padStart(2, '0');
    const year = validDate.getFullYear();
    return `${day}/${month}/${year}`;
  }, []);

  const normalizeDate = date => {
    const normalized = new Date(date);
    normalized.setHours(0, 0, 0, 0);
    return normalized;
  };
  const getDisplayDate = () => {
    if (startDate && endDate) {
      if (
        normalizeDate(startDate).getTime() === normalizeDate(endDate).getTime()
      ) {
        return formatDate(startDate);
      }
      return `${formatDate(startDate)} - ${formatDate(endDate)}`;
    }
    return dropdownLabel;
  };

  const isSelectedRange = useCallback(
    range => {
      const [rangeStart, rangeEnd] = range;
      const normalizedStartDate = startDate ? normalizeDate(startDate) : null;
      const normalizedEndDate = endDate ? normalizeDate(endDate) : null;
      const normalizedRangeStart = normalizeDate(rangeStart);
      const normalizedRangeEnd = normalizeDate(rangeEnd);
      const isRangeSelected =
        normalizedStartDate &&
        normalizedEndDate &&
        normalizedStartDate.getTime() === normalizedRangeStart.getTime() &&
        normalizedEndDate.getTime() === normalizedRangeEnd.getTime();
      return isRangeSelected;
    },
    [startDate, endDate]
  );

  const handleClickOutside = useCallback(
    event => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    },
    [setShowCalendar]
  );

  useEffect(() => {
    if (showCalendar) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCalendar, handleClickOutside]);

  const toggleCalendar = useCallback(() => {
    setShowCalendar(prevState => !prevState);
  }, [setShowCalendar]);

  const handleRangeSelect = useCallback(
    rangeKey => {
      const rangeDates = dateRanges[rangeKey];
      onDateChange(rangeDates);
      setShowCalendar(false);
    },
    [dateRanges, onDateChange, setShowCalendar]
  );

  const rangeButtons = useMemo(
    () => [
      { label: 'Today', key: 'today' },
      { label: 'Yesterday', key: 'yesterday' },
      { label: 'This Week', key: 'thisWeek' },
      { label: 'Last Week', key: 'lastWeek' },
      { label: 'This Month', key: 'thisMonth' },
      { label: 'Last Month', key: 'lastMonth' },
      { label: 'This Year', key: 'thisYear' },
      { label: 'Last Year', key: 'lastYear' }
    ],
    []
  );

  return (
    <>
      <button className="dropdown-select btn-no-style" onClick={toggleCalendar}>
        {getDisplayDate()}
      </button>

      {showCalendar && (
        <div ref={calendarRef} className="calendar-dropdown">
          <div className="dropdown-content">
            <div className="range-buttons">
              {rangeButtons.map(({ label, key }) => (
                <button
                  key={key}
                  className={isSelectedRange(dateRanges[key]) ? 'selected' : ''}
                  onClick={() => handleRangeSelect(key)}
                >
                  {label}
                </button>
              ))}
            </div>
            <DatePicker
              selectsRange={true}
              startDate={newStartDate}
              endDate={newEndDate}
              onChange={onDateChange}
              inline
              minDate={minDate}
              maxDate={new Date()}
            />
          </div>
        </div>
      )}
    </>
  );
};

Calendar.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  dropdownLabel: PropTypes.string.isRequired,
  showCalendar: PropTypes.bool.isRequired,
  setShowCalendar: PropTypes.func.isRequired
};

export default Calendar;
