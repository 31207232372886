import { React, useState, useEffect, useCallback } from 'react';
import { FaCheckCircle, FaTimesCircle, FaClock } from 'react-icons/fa';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import Flex from '../../common/flex';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Profile from '../../common/profile';
import ReturnTrackerRequest from '../../core/service/returnTrackerRequest';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import DataTransferService from '../../core/service/dataTransferService';
import Loader from '../../core/loader/loader';
import tyreImage from '../../assets/images/tyre.png';
import SearchInput from '../../common/search';
import ViewAll from '../../common/viewAll';
import SubtleBadge, {
  statusByColor,
  toPascalWord
} from '../../common/subtleBadge';
import { Back } from '../../common/back';
import Calendar from '../../common/calender';
import { formatLocalDate } from '../../common/shared';

const CustomerReturnTrackers = () => {
  const [isFocused, setIsFocused] = useState(false);
  const dataTransferService = new DataTransferService();
  const [returnStatusCounts, setReturnStatusCounts] = useState({
    IN_PROCESS: 0,
    PASSED_QC: 0,
    FAILED_QC: 0
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const [pageIndex] = useState(0);
  const [searchPageIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [pageSize] = useState(10);
  const [returnRequests, setReturnRequests] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState('Calender');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { customerName } = useParams();

  const fetchReturnServices = useCallback(
    (startDate, endDate) => {
      setLoading(true);
      const formattedStartDate = new Date(
        startDate.getTime() - startDate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split('T')[0];

      const formattedEndDate = new Date(
        endDate.getTime() - endDate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split('T')[0];
      const returnTrackerRequest = new ReturnTrackerRequest();
      returnTrackerRequest
        .fetchCustomerReturnsCounts(
          organisationName,
          branchName,
          customerName,
          formattedStartDate,
          formattedEndDate,
          pageIndex,
          pageSize
        )
        .then(response => {
          setLoading(false);
          if (response?.data?.responseCode === 200) {
            setErrorMessage('');
            setReturnStatusCounts({
              IN_PROCESS:
                response.data.responseBody.returnStatusCounts?.IN_PROCESS || 0,
              PASSED_QC:
                response.data.responseBody.returnStatusCounts?.PASSED_QC || 0,
              FAILED_QC:
                response.data.responseBody.returnStatusCounts?.FAILED_QC || 0
            });

            setReturnRequests(response.data.responseBody.allReturnRequests);
            setTotalItems(response.data.responseBody.totalItems);
          }
        })
        .catch(error => {
          setLoading(false);
          handleErrorResponse('fetchReturnServices', error.response);
          setErrorMessage(error.response?.data?.responseMessage);
          setReturnStatusCounts({
            IN_PROCESS: 0,
            PASSED_QC: 0,
            FAILED_QC: 0
          });
          setTotalItems(0);
        });
    },
    [pageIndex, pageSize, organisationName, branchName, customerName]
  );

  const searchReturnRequests = useCallback(() => {
    setLoading(true);
    setErrorMessage('');
    const returnTrackerRequest = new ReturnTrackerRequest();
    returnTrackerRequest
      .searchReturnsByCustomerName(
        organisationName,
        branchName,
        customerName,
        searchTerm,
        searchPageIndex,
        pageSize
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          const allReturnRequests =
            response.data.responseBody.allReturnRequests;
          const statusCounts = allReturnRequests.reduce(
            (acc, request) => {
              acc[request.returnStatus] = (acc[request.returnStatus] || 0) + 1;
              return acc;
            },
            { IN_PROCESS: 0, PASSED_QC: 0, FAILED_QC: 0 }
          );
          setReturnRequests(allReturnRequests);
          setTotalItems(response.data.responseBody.totalItems);
          setReturnStatusCounts(statusCounts);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('fetchReturnRequests', error.response);
        setErrorMessage(error.response?.data?.responseMessage);
        setTotalItems(0);
        setReturnStatusCounts({
          IN_PROCESS: 0,
          PASSED_QC: 0,
          FAILED_QC: 0
        });
      });
  }, [
    branchName,
    customerName,
    organisationName,
    searchTerm,
    searchPageIndex,
    pageSize
  ]);

  useEffect(() => {
    if (searchTerm) {
      searchReturnRequests(searchPageIndex, pageSize);
    }
  }, [searchTerm, searchPageIndex, pageSize, searchReturnRequests]);

  useEffect(() => {
    if (!searchTerm && startDate && endDate) {
      const formattedStart = startDate.toLocaleDateString();
      const formattedEnd = endDate.toLocaleDateString();
      setDropdownLabel(
        startDate.toDateString() === endDate.toDateString()
          ? formattedStart
          : `${formattedStart} - ${formattedEnd}`
      );
      fetchReturnServices(startDate, endDate);
    }
  }, [searchTerm, startDate, endDate, fetchReturnServices]);

  const handleDateChange = update => {
    const [start, end] = update;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      const formattedStart = start.toLocaleDateString();
      const formattedEnd = end.toLocaleDateString();
      setDropdownLabel(`${formattedStart} - ${formattedEnd}`);
      setShowCalendar(false);
    }
  };

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const navigate = useNavigate();

  const handlenavigate = () => {
    navigate('/new-return-tracker');
  };

  const formatDate = dateString => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleSerialClick = request => {
    setShowPopup(true);
    document.body.style.overflow = 'hidden';
    setPopupData(request);
  };

  const closePopup = () => {
    setShowPopup(false);
    document.body.style.overflow = '';
  };

  const handlePrintTyreDetails = () => {
    document.title = popupData.serialNumber;
    window.print();
  };

  const handleSortClick = () => {
    setShowCalendar(false);
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}
      <div className="d-flex justify-content-end mt-15 profile-user">
        <Profile />
      </div>

      <div className="count-container d-flex justify-content-evenly align-items-center mt-5">
        <div className="text-center d-flex count-containers">
          <div className="process-icon">
            <FaClock className="clock-icon fs-2" />
          </div>
          <div className="ms-3">
            <p className="count-head fs-14">In Process</p>
            <p className="fw-bold count-number-style">
              {returnStatusCounts.IN_PROCESS}
            </p>
          </div>
        </div>

        <div className="text-center d-flex count-containers">
          <div className="passed-icon">
            <FaCheckCircle className="tick-icon fs-2" />
          </div>
          <div className="ps-3">
            <p className="count-head fs-14">Passed QC</p>
            <p className="fw-bold count-number-style">
              {returnStatusCounts.PASSED_QC}
            </p>
          </div>
        </div>

        <div className="text-center d-flex count-containers">
          <div className="failed-icon">
            <FaTimesCircle className="fs-2 wrong-icon" />
          </div>
          <div className="ps-3">
            <p className="count-head fs-14">Failed QC</p>
            <p className="fw-bold count-number-style">
              {returnStatusCounts.FAILED_QC}
            </p>
          </div>
        </div>
      </div>

      <div className="card-all-return-requests p-4">
        <div className="d-flex align-items-center">
          <Back />
          <p className="ps-2 fs-22 fw-600">{customerName}</p>
        </div>
        <Flex className="mt-3 mb-3 align-items-center justify-content-end">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search"
            className="w-25"
            id="customer-return-tracker-search-input"
          />
          {!searchTerm && (
            <div className="d-flex justify-content-end align-items-center">
              <div className="dropdown-select d-flex align-items-center">
                <button
                  onClick={handleSortClick}
                  className="fs-14 fw-500 grayish-blue pe-2 text-nowrap btn-no-style"
                >
                  Sort by:
                </button>
                <Calendar
                  startDate={formatLocalDate(startDate)}
                  endDate={formatLocalDate(endDate)}
                  onDateChange={handleDateChange}
                  dropdownLabel={dropdownLabel}
                  showCalendar={showCalendar}
                  setShowCalendar={setShowCalendar}
                />
              </div>
            </div>
          )}
        </Flex>
        <Flex className="align-items-center justify-content-end ">
          <button
            type="button"
            className="btn btn-outline custom-btn-outline mb-2"
            onClick={handlenavigate}
          >
            + Create a new Return Tracker
          </button>
        </Flex>

        <Table>
          <thead>
            <tr>
              <th className="fw-500 table-headings">Serial Number</th>
              <th className="fw-500 table-headings">Company</th>
              <th className="fw-500 table-headings">Model Name</th>
              <th className="fw-500 table-headings">Customer name</th>
              <th className="fw-500 table-headings">Created</th>
              <th className="fw-500 table-headings">Status</th>
              <th className="fw-500 table-headings">Edit</th>
            </tr>
          </thead>
          <tbody>
            {errorMessage ? (
              <tr>
                <td colSpan="7" className="text-center">
                  <div className="text-center text-danger">{errorMessage}</div>
                </td>
              </tr>
            ) : (
              returnRequests.map(request => {
                return (
                  <tr key={request.serialNumber}>
                    <td
                      className="pointer ps-3"
                      id="serial-color"
                      data-bs-toggle="modal"
                      onClick={() => handleSerialClick(request)}
                    >
                      {request.serialNumber}
                    </td>
                    <td className="ps-3">
                      {request.company === 'OTHER'
                        ? request.customTyreManufacturer
                        : request.tyreManufacturer}
                    </td>
                    <td className="limited-text ps-3">
                      {request.tyreModel === 'OTHER'
                        ? request.customTyreModel
                        : request.tyreModel}
                    </td>
                    <td className="ps-3">{request.customerName}</td>
                    <td className="ps-3">
                      {formatDate(request.created.createdTime)}
                    </td>
                    <td className="ps-3">
                      <SubtleBadge
                        bg={statusByColor(request.returnStatus)}
                        className="fs-14 fw-500"
                      >
                        {toPascalWord(request.returnStatus)}
                      </SubtleBadge>
                    </td>
                    <td className="ps-3">
                      <Link
                        to={`/edit-return-tracker/${request.serialNumber}`}
                        style={{
                          color: '#127BFF',
                          textDecoration: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        Edit
                      </Link>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        {showPopup && popupData && (
          <button
            className="modal show d-block d-flex justify-content-center "
            id="popupModal"
            tabIndex="-1"
            role="dialog"
            onClick={closePopup}
          >
            <button
              className="modal-dialog btn-no-style"
              onClick={e => e.stopPropagation()}
            >
              <div className="modal-content">
                <div className="ls-data modal-header">
                  <div className="tyre-logo">
                    <img src={tyreImage} alt={'tyre-image'} />
                  </div>
                  <div className="s-number">
                    {popupData.serialNumber && (
                      <p className="s-number mb-0 fs-3 fw-medium">
                        {popupData.serialNumber}
                      </p>
                    )}
                  </div>
                </div>
                <div className="status-tab">
                  <div className="row top-box d-flex justify-content-between pb-3 ">
                    {popupData.created && (
                      <div className="col-7 created-style">
                        <p className="fw-normal cs-data fs-6 ps-4">
                          Created On:
                        </p>
                        <p className="data-info fw-light pt-2 fs-6 ps-4">
                          {new Date(popupData.created.createdTime)
                            .toISOString()
                            .split('T')[0]
                            .split('-')
                            .reverse()
                            .join('/')}
                        </p>
                      </div>
                    )}

                    {popupData.returnStatus && (
                      <div className="col-5 status-style">
                        <p className="fw-normal ss-data fs-6">Status:</p>
                        <p className="pt-2 fs-6">
                          <SubtleBadge
                            bg={statusByColor(popupData.returnStatus)}
                            className="fs-14 fw-500"
                          >
                            {toPascalWord(popupData.returnStatus)}
                          </SubtleBadge>
                        </p>
                      </div>
                    )}
                  </div>
                  <hr className="divideline mt-0 mb-0"></hr>
                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className="pointer printing fs-6 btn-no-style p-3"
                      onClick={handlePrintTyreDetails}
                    >
                      <FontAwesomeIcon
                        icon={faPrint}
                        className="icon-spacing"
                      />
                      Print
                    </button>
                  </div>
                </div>
                <div className="modal-body">
                  <div className="details-tab">
                    <div className="container text-start">
                      {popupData.tyreManufacturer && (
                        <div className="row">
                          <p className="col-6 data">Tyre Make:</p>
                          <p className="col-6 data-info fw-light fs-6">
                            {popupData.tyreManufacturer}
                          </p>
                        </div>
                      )}
                      {popupData.tyreModel && (
                        <div className="row">
                          <p className="col-6 data">Tyre Model:</p>
                          <p className="col-6 data-info fw-light text-break fs-6">
                            {popupData.tyreModel}
                          </p>
                        </div>
                      )}
                      {popupData.customerName && (
                        <div className="row">
                          <p className="col-6 data">Customer Name:</p>
                          <p className="col-6 data-info fw-light fs-6">
                            {popupData.customerName}
                          </p>
                        </div>
                      )}
                      {popupData.manufacturerId && (
                        <div className="row">
                          <p className="col-6 data">Manufacturer Id:</p>
                          <p className="col-6 data-info fw-light fs-6">
                            {popupData.manufacturerId}
                          </p>
                        </div>
                      )}
                      {popupData.returnStatus && (
                        <div className="row">
                          <p className="col-6 data">Claim Status:</p>
                          <p className="col-6 data-info fs-6">
                            <SubtleBadge
                              bg={statusByColor(popupData.returnStatus)}
                              className="fs-14 fw-500"
                            >
                              {toPascalWord(popupData.returnStatus)}
                            </SubtleBadge>
                          </p>
                        </div>
                      )}
                    </div>
                    {popupData.returnStatus === 'PASSED_QC' && (
                      <p className="pt-4 fw-normal note text-start">
                        The return request of the tyre has been accepted by the
                        company. We will replace the tyre once we receive it
                        from the company.
                      </p>
                    )}

                    {popupData.returnStatus === 'FAILED_QC' && (
                      <p className="pt-4 fw-normal note text-start">
                        The return request has not been accepted by the company.
                      </p>
                    )}

                    {popupData.returnStatus === 'IN_PROCESS' && (
                      <p className="pt-4 fw-normal note text-start">
                        The return request is being processed by the company.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </button>
          </button>
        )}
        <div className="mt-4">
          <ViewAll
            totalItems={totalItems}
            to={`/view-all-customer-return-trackers/${customerName}/${formatLocalDate(startDate)}/${formatLocalDate(endDate)}`}
          />
        </div>
      </div>
    </div>
  );
};
export default CustomerReturnTrackers;
