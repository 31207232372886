import ServiceRequest from '../core/service/serviceRequest';
import { handleErrorResponse } from '../core/tokenInterceptor/axiosInstance';
import { toast } from 'react-toastify';
import UserService from '../core/service/userService';
import ReturnTrackerRequest from '../core/service/returnTrackerRequest';
import { toPascalCase } from './shared';
import InvoiceService from '../core/service/invoiceService';
import DataTransferService from '../core/service/dataTransferService';

export const vehicleModel = (
  vehicleType,
  vehicleManufacturer,
  { setLoading, setResponseVehicleModel }
) => {
  const serviceRequest = new ServiceRequest();
  setLoading(true);
  serviceRequest
    .vehicleModels(vehicleType, vehicleManufacturer.toUpperCase())
    .then(response => {
      if (response.data.responseCode === 200) {
        setLoading(false);
        setResponseVehicleModel(
          response.data.responseBody.map(model => ({
            label: model,
            value: model.toLowerCase()
          }))
        );
      }
    })
    .catch(error => {
      setLoading(false);
      handleErrorResponse('vehicle-model', error.response);
    });
};

export const confirmDelete = (
  selectedUser,
  organisationName,
  fetchUsers,
  setLoading,
  setShowDeleteModal,
  setUsers
) => {
  setLoading(true);
  const userService = new UserService();
  userService
    .deleteUser(selectedUser)
    .then(response => {
      if (response.data.responseCode === 200) {
        fetchUsers(organisationName);
        setUsers(prevUsers =>
          prevUsers.filter(user => user.userMobileNumber !== selectedUser)
        );
        toast.success(response.data.responseMessage, {
          theme: 'colored'
        });
      }
      setLoading(false);
      setShowDeleteModal(false);
    })
    .catch(error => {
      setLoading(false);
      handleErrorResponse('delete-user', error.response);
      setShowDeleteModal(false);
    });
};

export const tyreModel = (
  tyreManufacturer,
  { setLoading, setResponseTyreModel }
) => {
  const returnTrackerRequest = new ReturnTrackerRequest();
  setLoading(true);
  returnTrackerRequest
    .tyreModels(tyreManufacturer.toUpperCase())
    .then(response => {
      if (response.data.responseCode === 200) {
        setLoading(false);
        setResponseTyreModel(
          response.data.responseBody.map(model => ({
            label: model,
            value: model.toLowerCase()
          }))
        );
      }
    })
    .catch(error => {
      setLoading(false);
      handleErrorResponse('tyreModel', error.response);
    });
};

export const findVehicle = (
  vehicleNumber,
  { setLoading, vehicleType, formik, setDisableFields }
) => {
  const userService = new UserService();
  setLoading(true);
  if (!vehicleNumber) {
    setLoading(false);
    return;
  }
  userService
    .findVehicle(vehicleNumber)
    .then(response => {
      if (response.data.responseCode === 200) {
        const responseBody = response.data.responseBody;

        if (responseBody.vehicleType !== vehicleType) {
          setLoading(false);
          formik.resetForm();
          toast.error(
            `The vehicle with the number ${vehicleNumber} does not match the current type ${vehicleType}. Please verify the vehicle number or select the correct vehicle type.`
          );
          return;
        }

        formik.setFieldValue(
          'customerMobileNumber',
          responseBody.customerMobileNumber
        );
        formik.setFieldValue('customerName', responseBody.customerName);
        formik.setFieldValue(
          'numberOfVehicleWheels',
          responseBody.vehicleWheels
        );
        formik.setFieldValue(
          'vehicleManufacturer',
          toPascalCase(responseBody.vehicleManufacturer)
        );

        formik.setFieldValue(
          'vehicleModel',
          toPascalCase(responseBody.vehicleModel)
        );

        formik.setFieldValue(
          'vehicleOdometerReading',
          responseBody.vehicleOdometerReading
        );
        formik.setFieldValue(
          'vehicleAverageKmsPerDay',
          responseBody.vehicleAverageKmsPerDay
        );
        formik.setFieldValue(
          'driverMobileNumber',
          responseBody.driverMobileNumber
        );
        setDisableFields(true);
        setLoading(false);
      }
    })
    .catch(error => {
      if (setDisableFields && error.response.data.responseCode === 404) {
        setDisableFields(false);
        formik.setFieldValue('customerMobileNumber', '');
        formik.setFieldValue('customerName', '');
        formik.setFieldValue('numberOfVehicleWheels', '');
        formik.setFieldValue('vehicleManufacturer', '');
        formik.setFieldValue('vehicleModel', '');
        formik.setFieldValue('vehicleOdometerReading', '');
        formik.setFieldValue('vehicleAverageKmsPerDay', '');
        formik.setFieldValue('driverMobileNumber', '');
        setLoading(false);
        return;
      }
      handleErrorResponse('vehicleModel', error.response);
    });
};

export const findCustomer = (
  mobileNumber,
  { setLoading, formik, setCustomerDisableFields }
) => {
  const userService = new UserService();
  setLoading(true);
  if (!mobileNumber) {
    setLoading(false);
    return;
  }
  userService
    .findCustomer(mobileNumber)
    .then(response => {
      if (response.data.responseCode === 200) {
        const responseBody = response.data.responseBody;
        formik.setFieldValue('customerName', responseBody.customerName);
        setCustomerDisableFields(true);
        setLoading(false);
      }
    })
    .catch(error => {
      if (
        setCustomerDisableFields &&
        error.response.data.responseCode === 404
      ) {
        setCustomerDisableFields(false);
        formik.setFieldValue('customerName', '');
        setLoading(false);
        return;
      }
      handleErrorResponse('findCustomer', error.response);
    });
};

export const getVehicleManufacturers = (
  vehicleType,
  { setLoading, setVehicleManfacturerResponse }
) => {
  const serviceRequest = new ServiceRequest();
  serviceRequest
    .vehicleManufacturer(vehicleType)
    .then(response => {
      if (response.data.responseCode === 200) {
        const pascalCasedManufacturers = response.data.responseBody.map(
          manufacturer => toPascalCase(manufacturer)
        );
        setVehicleManfacturerResponse(pascalCasedManufacturers);
      }
    })
    .catch(error => {
      setLoading(false);
      handleErrorResponse('get-vehicle-manufacturer', error.response);
    });
};

export const submitForm = (formattedData, { setLoading, navigate }) => {
  const invoiceService = new InvoiceService();
  const dataTransferService = new DataTransferService();
  setLoading(true);
  invoiceService
    .generateCarInvoice(
      dataTransferService.getSelectedBranch(),
      dataTransferService.getSelectedOrganization(),
      formattedData
    )
    .then(response => {
      if (response.data.responseCode === 200) {
        setLoading(false);
        navigate(`/invoice/${response.data.responseBody}`);
        toast.success('Invoice Generated Successfully', {
          theme: 'colored'
        });
      }
    })
    .catch(error => {
      setLoading(false);
      handleErrorResponse('generate-invoice', error.response);
    });
};
