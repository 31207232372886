import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Profile from '../../common/profile';
import { newReturnTrackerValidations } from '../../validations/forms';
import ReturnTrackerService from '../../core/service/returnTrackerService';
import DataTransferService from '../../core/service/dataTransferService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import Loader from '../../core/loader/loader';
import { Back } from '../../common/back';
import Flex from '../../common/flex';
import { InputField, SelectField } from '../../validations/inputFields';
import { toPascalCase } from '../../common/shared';
import { tyreModel, findCustomer } from '../../common/commonApis';
import ReturnTrackerRequest from '../../core/service/returnTrackerRequest';

const NewReturnTracker = () => {
  const [currentDate, setCurrentDate] = useState('');
  const [loading, setLoading] = useState(false);
  const returnTrackerService = new ReturnTrackerService();
  const dataTransferService = new DataTransferService();
  const [responseTyreModel, setResponseTyreModel] = useState('');
  const [tyreManfacturerResponse, setTyreManfacturerResponse] = useState([]);
  const [customerDisableFields, setCustomerDisableFields] = useState(false);
  const [disableFields] = useState(false);

  const navigate = useNavigate();

  const initialValues = {
    serialNumber: '',
    manufacturerReferenceId: '',
    tyreManufacturer: '',
    tyreModel: '',
    customerName: '',
    customerMobileNumber: '',
    createdOn: '',
    returnStatus: ''
  };

  const getTyreManufacturers = ({ setLoading, setTyreManfacturerResponse }) => {
    const returnTrackerRequest = new ReturnTrackerRequest();
    returnTrackerRequest
      .fetchTyreManufacturers()
      .then(response => {
        if (response.data.responseCode === 200) {
          const pascalCasedManufacturers = response.data.responseBody.map(
            manufacturer => toPascalCase(manufacturer)
          );
          setTyreManfacturerResponse(pascalCasedManufacturers);
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('get-vehicle-manufacturer', error.response);
      });
  };

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setCurrentDate(formattedDate);
    getTyreManufacturers({ setLoading, setTyreManfacturerResponse });
  }, []);

  const handleSubmit = async values => {
    const formattedData = {
      serialNumber: values.serialNumber,
      manufacturerReferenceId: values.manufacturerReferenceId
        .trim()
        .replace(/\s+/g, ' '),
      tyreManufacturer: values.tyreManufacturer.toUpperCase(),
      tyreModel: values.tyreModel.toUpperCase(),
      returnStatus: values.returnStatus,
      customerDTO: {
        customerName: values.customerName.trim().replace(/\s+/g, ' '),
        customerMobileNumber: values.customerMobileNumber
      }
    };
    submitForm(formattedData);
  };

  const submitForm = formattedData => {
    setLoading(true);
    const branchName = dataTransferService.getSelectedBranch();
    const organisationName = dataTransferService.getSelectedOrganization();
    returnTrackerService
      .createReturnTracker(formattedData, branchName, organisationName)
      .then(response => {
        if (response.data.responseCode === 201) {
          setLoading(false);
          navigate('/return-tracker');
          toast.success(`${response.data.responseMessage}`, {
            theme: 'colored'
          });
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('submitForm', error.response);
      });
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <div className="d-flex justify-content-end mt-15">
        <Profile />
      </div>
      <div className="container new-return-tracker-container p-4">
        <Flex className="align-items-center justify-content-start">
          <Back />
          <p className="padding-heading fw-600 ps-2">
            Create New Return Tracker
          </p>
        </Flex>

        <div>
          <hr className="pt-3 hr-color"></hr>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={newReturnTrackerValidations}
          onSubmit={handleSubmit}
        >
          {formik => (
            <Form>
              <div className="row pe-4 ps-3">
                <div className="col">
                  <InputField
                    label="Serial Number"
                    name="serialNumber"
                    type="text"
                    placeholder="Enter Tyre Serial Number"
                    className="form-control narrow-input"
                  />
                </div>
                <div className="col">
                  <InputField
                    label="Manufacturer Reference Id"
                    name="manufacturerReferenceId"
                    type="text"
                    placeholder="Enter Reference Tracker Id"
                    className="form-control narrow-input"
                  />
                </div>
              </div>
              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <SelectField
                    label="Tyre Manufacturer"
                    name="tyreManufacturer"
                    className="form-select narrow-input"
                    options={tyreManfacturerResponse}
                    onChange={e => {
                      const selectedTyreManufacturer = e.target.value;
                      selectedTyreManufacturer.toUpperCase();
                      formik.setFieldValue(
                        'tyreManufacturer',
                        selectedTyreManufacturer
                      );
                      tyreModel(selectedTyreManufacturer, {
                        setLoading,
                        setResponseTyreModel
                      });
                    }}
                  />
                </div>

                <div className="col">
                  <SelectField
                    label="Tyre Model"
                    name="tyreModel"
                    className="form-select narrow-input"
                    options={
                      responseTyreModel.length > 0
                        ? responseTyreModel.map(model =>
                            toPascalCase(model.label)
                          )
                        : []
                    }
                    disabled={
                      !formik.values.tyreManufacturer ||
                      responseTyreModel.length === 0 ||
                      loading
                    }
                  />
                </div>
              </div>
              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <InputField
                    label="Customer Mobile Number"
                    name="customerMobileNumber"
                    type="text"
                    placeholder="Enter Customer Mobile Number"
                    className="form-control w-75"
                    onBlur={e => {
                      const customerMobileNumber = e.target.value;
                      formik.setFieldValue(
                        'customerMobileNumber',
                        customerMobileNumber
                      );
                      findCustomer(customerMobileNumber, {
                        setLoading,
                        formik,
                        setCustomerDisableFields
                      });
                    }}
                    disabled={disableFields}
                  />
                </div>
                <div className="col">
                  <InputField
                    label="Customer Name"
                    name="customerName"
                    type="text"
                    placeholder="Enter Customer Name"
                    className="form-control w-75"
                    disabled={disableFields || customerDisableFields}
                  />
                </div>
              </div>
              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <label className="fs-14 fw-500" htmlFor="createdOn">
                    Created On
                  </label>
                  <Field
                    type="date"
                    name="createdOn"
                    className="form-control narrow-input"
                    value={currentDate}
                    disabled
                    id="createdOn"
                  />
                  <ErrorMessage
                    name="createdOn"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col">
                  <SelectField
                    label="Return Status"
                    name="returnStatus"
                    className="form-select narrow-input"
                    options={['IN_PROCESS', 'PASSED_QC', 'FAILED_QC']}
                  />
                </div>
              </div>
              <div className="mt-4 mb-3 d-flex justify-content-end">
                <button type="submit" className="button-indigo">
                  Create
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default NewReturnTracker;
