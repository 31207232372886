import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link, useParams } from 'react-router-dom';
import Profile from '../../common/profile';
import Loader from '../../core/loader/loader';
import { PaginationComponent } from '../../common/pagination';
import ServiceRequest from '../../core/service/serviceRequest';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import Flex from '../../common/flex';
import DataTransferService from '../../core/service/dataTransferService';
import SearchInput from '../../common/search';
import { Back } from '../../common/back';
import { Table } from 'react-bootstrap';
import { numberFormatter } from '../../common/numberFormatter';
import Calendar from '../../common/calender';
import { formatLocalDate } from '../../common/shared';

const RecentServices = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [searchPageIndex, setSearchPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [errorMessage, setErrorMessage] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState('Calender');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { sDate, eDate } = useParams();
  const navigate = useNavigate();

  const fetchServicesWithDate = useCallback(
    (startDate, endDate) => {
      setLoading(true);
      const serviceRequest = new ServiceRequest();
      const dataTransferService = new DataTransferService();
      serviceRequest
        .fetchServicesWithDate(
          dataTransferService.getSelectedOrganization(),
          dataTransferService.getSelectedBranch(),
          pageIndex,
          pageSize,
          formatLocalDate(startDate),
          formatLocalDate(endDate)
        )
        .then(response => {
          if (response?.data?.responseCode === 200) {
            setErrorMessage('');
            setServicesData(response.data.responseBody.services);
            setTotalItems(response.data.responseBody.TotalItems);
            setSearchPageIndex(0);
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          handleErrorResponse('fetchServicesWithDate', error.response);
          setErrorMessage(error.response?.data?.responseMessage);
          setTotalItems(0);
          setSearchPageIndex(0);
        });
    },
    [pageIndex, pageSize]
  );

  const searchService = useCallback(() => {
    setLoading(true);
    setErrorMessage('');
    const serviceRequest = new ServiceRequest();
    const dataTransferService = new DataTransferService();
    serviceRequest
      .searchServices(
        searchTerm,
        searchPageIndex,
        pageSize,
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization()
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setServicesData(response.data.responseBody.services);
          setTotalItems(response.data.responseBody.TotalItems);
          setLoading(false);
          setPageIndex(0);
        }
      })
      .catch(error => {
        setPageIndex(0);
        setLoading(false);
        handleErrorResponse('searchServices', error.response);
        setErrorMessage(error.response.data.responseMessage);
        setTotalItems(0);
      });
  }, [searchTerm, pageSize, searchPageIndex]);

  useEffect(() => {
    if (searchTerm) {
      searchService();
    }
  }, [searchTerm, searchService]);

  useEffect(() => {
    if (!searchTerm && sDate && eDate) {
      setStartDate(sDate);
      setEndDate(eDate);
      fetchServicesWithDate(sDate, eDate);
    }
  }, [sDate, eDate, searchTerm, fetchServicesWithDate]);

  const handleDateChange = update => {
    const [start, end] = update;
    setStartDate(start);
    setPageIndex(0);
    setSearchPageIndex(0);

    if (end) {
      const currentEndDate = new Date(end);
      currentEndDate.setHours(23, 59, 59, 999);
      setEndDate(currentEndDate);
      const formattedStart = start?.toLocaleDateString();
      const formattedEnd = currentEndDate.toLocaleDateString();
      navigate(
        `/recent-services/${start.toISOString()}/${currentEndDate.toISOString()}`
      );
      setDropdownLabel(`${formattedStart} - ${formattedEnd}`);
      setShowCalendar(false);
      setPageIndex(0);
    } else {
      setEndDate(null);
    }
  };

  const handleSortClick = () => {
    setShowCalendar(false);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setPageIndex(0);
  };

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  const prevPage = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1);
  };

  const searchPrevPage = () => {
    if (searchPageIndex > 0) setSearchPageIndex(searchPageIndex - 1);
  };

  const nextPage = () => {
    if (pageIndex < Math.ceil(totalItems / pageSize) - 1)
      setPageIndex(pageIndex + 1);
  };

  const searchNextPage = () => {
    if (searchPageIndex < Math.ceil(totalItems / pageSize) - 1)
      setSearchPageIndex(searchPageIndex + 1);
  };

  const onPageChange = newPageIndex => {
    setPageIndex(newPageIndex);
  };

  const onSearchPageChange = newPageIndex => {
    setSearchPageIndex(newPageIndex);
  };

  const handleNavigate = () => {
    navigate('/select-vehicle');
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <Flex className="d-flex align-items-center justify-content-end">
        <Profile />
      </Flex>

      <div className="mt-5 p-4 select-vehicle-container">
        <Flex className="align-items-center justify-content-start">
          <Back />
          <p className="fs-22 fw-600 ps-2">Recent Services</p>
        </Flex>

        <Flex className="pt-3 align-items-center justify-content-end">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search"
            className="w-25"
            id="recent-services-search-input"
          />

          {!searchTerm && (
            <div className="d-flex justify-content-end align-items-center">
              <div className="dropdown-select d-flex align-items-center">
                <button
                  onClick={handleSortClick}
                  className="fs-14 fw-500 grayish-blue pe-2 text-nowrap btn-no-style"
                >
                  Sort by:
                </button>
                <Calendar
                  startDate={formatLocalDate(startDate)}
                  endDate={formatLocalDate(endDate)}
                  onDateChange={handleDateChange}
                  dropdownLabel={dropdownLabel}
                  showCalendar={showCalendar}
                  setShowCalendar={setShowCalendar}
                />
              </div>
            </div>
          )}
        </Flex>

        <Flex className="mt-3 align-items-center justify-content-end">
          <button
            type="button"
            className="btn btn-outline custom-btn-outline"
            onClick={handleNavigate}
          >
            + New Service
          </button>
        </Flex>

        <div>
          <Table className="table">
            <thead>
              <tr>
                <th scope="col">Vehicle Number</th>
                <th scope="col">Invoice Number</th>
                <th scope="col">Customer Name </th>
                <th scope="col">Category</th>
                <th scope="col">Amount</th>
                <th scope="col">Payment Type</th>
                <th scope="col">Invoice</th>
              </tr>
            </thead>

            <tbody>
              {errorMessage ? (
                <tr>
                  <td colSpan="6" className="text-center">
                    <div className="text-center text-danger">
                      {errorMessage}
                    </div>
                  </td>
                </tr>
              ) : (
                servicesData.map(service => (
                  <tr key={service.invoiceSerialNumber}>
                    <td> {service.vehicleNumber}</td>
                    <td> {service.invoiceSerialNumber}</td>
                    <td> {service.customerName}</td>
                    <td>{service.vehicleType}</td>
                    <td>{`Rs.${Number(service.amount).toFixed(0)}`}</td>
                    <td> {service.paymentType || 'N/A'}</td>
                    <td>
                      <Link
                        to={`/invoice/${service.invoiceSerialNumber}`}
                        style={{ color: '#127BFF', textDecoration: 'none' }}
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>

        <Flex className="mt-4 justify-content-between align-items-center fs-14 fw-500 grayish-blue">
          {!searchTerm ? (
            <span>
              {totalItems > 0
                ? `Showing ${pageIndex * pageSize + 1} to ${Math.min(
                    (pageIndex + 1) * pageSize,
                    totalItems
                  )} of ${numberFormatter(totalItems)} entries`
                : 'No entries available'}
            </span>
          ) : (
            <span>
              {totalItems > 0
                ? `Showing ${searchPageIndex * pageSize + 1} to ${Math.min(
                    (searchPageIndex + 1) * pageSize,
                    totalItems
                  )} of ${numberFormatter(totalItems)} entries`
                : 'No entries available'}
            </span>
          )}
          {!searchTerm ? (
            <PaginationComponent
              prevPage={prevPage}
              nextPage={nextPage}
              isNextDisabled={pageIndex >= Math.ceil(totalItems / pageSize) - 1}
              isPrevDisabled={pageIndex === 0}
              pageIndex={pageIndex}
              totalItems={totalItems}
              pageSize={pageSize}
              onPageChange={onPageChange}
            />
          ) : (
            <PaginationComponent
              prevPage={searchPrevPage}
              nextPage={searchNextPage}
              isNextDisabled={
                searchPageIndex >= Math.ceil(totalItems / pageSize) - 1
              }
              isPrevDisabled={searchPageIndex === 0}
              pageIndex={searchPageIndex}
              totalItems={totalItems}
              pageSize={pageSize}
              onPageChange={onSearchPageChange}
            />
          )}
        </Flex>
      </div>
    </div>
  );
};

export default RecentServices;
