import React, { useState, useEffect, useCallback } from 'react';
import { FaCheckCircle, FaTimesCircle, FaClock } from 'react-icons/fa';
import { Table } from 'react-bootstrap';
import Flex from '../../common/flex';
import { Link, useNavigate } from 'react-router-dom';
import Profile from '../../common/profile';
import ReturnTrackerRequest from '../../core/service/returnTrackerRequest';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import DataTransferService from '../../core/service/dataTransferService';
import Loader from '../../core/loader/loader';
import SearchInput from '../../common/search';
import ViewAll from '../../common/viewAll';
import SubtleBadge, {
  statusByColor,
  toPascalWord
} from '../../common/subtleBadge';
import Calendar from '../../common/calender';
import { formatLocalDate } from '../../common/shared';

const ReturnTrackerHome = () => {
  const [isFocused, setIsFocused] = useState(false);
  const dataTransferService = new DataTransferService();
  const [returnStatusCounts, setReturnStatusCounts] = useState({
    IN_PROCESS: 0,
    PASSED_QC: 0,
    FAILED_QC: 0
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const [pageIndex] = useState(0);
  const [searchPageIndex] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [pageSize] = useState(10);
  const [returnRequests, setReturnRequests] = useState([]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState('Calendar');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const fetchReturnServices = useCallback(
    (startDate, endDate) => {
      setLoading(true);
      const formattedStartDate = new Date(
        startDate.getTime() - startDate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split('T')[0];

      const formattedEndDate = new Date(
        endDate.getTime() - endDate.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split('T')[0];

      const returnTrackerRequest = new ReturnTrackerRequest();
      returnTrackerRequest
        .fetchBranchDetailsWithReturnCounts(
          organisationName,
          branchName,
          formattedStartDate,
          formattedEndDate,
          pageIndex,
          pageSize
        )
        .then(response => {
          setLoading(false);
          if (response?.data?.responseCode === 200) {
            setErrorMessage('');
            setReturnStatusCounts(
              response.data.responseBody.returnStatusCounts || {}
            );
            setReturnRequests(response.data.responseBody.allReturnRequests);
            setTotalElements(response.data.responseBody.totalItems);
          }
        })
        .catch(error => {
          setLoading(false);
          handleErrorResponse('fetchReturnServices', error.response);
          setErrorMessage(
            error.response?.data?.responseMessage || 'An error occurred'
          );
          setTotalElements(0);
          setReturnStatusCounts({
            IN_PROCESS: 0,
            PASSED_QC: 0,
            FAILED_QC: 0
          });
        });
    },
    [branchName, organisationName, pageIndex, pageSize]
  );

  const searchReturnRequests = useCallback(
    (searchPageIndex, pageSize) => {
      setLoading(true);
      setErrorMessage('');
      const returnTrackerRequest = new ReturnTrackerRequest();
      returnTrackerRequest
        .searchReturnsBySerialNumber(
          organisationName,
          branchName,
          searchTerm,
          searchPageIndex,
          pageSize
        )
        .then(response => {
          if (response.data.responseCode === 200) {
            const allReturnRequests =
              response.data.responseBody.allReturnRequests;
            const statusCounts = allReturnRequests.reduce(
              (acc, request) => {
                acc[request.returnStatus] =
                  (acc[request.returnStatus] || 0) + 1;
                return acc;
              },
              { IN_PROCESS: 0, PASSED_QC: 0, FAILED_QC: 0 }
            );
            setReturnRequests(allReturnRequests);
            setTotalElements(response.data.responseBody.totalItems);
            setReturnStatusCounts(statusCounts);
            setLoading(false);
          }
        })
        .catch(error => {
          setLoading(false);
          handleErrorResponse('searchReturnRequests', error.response);
          setErrorMessage(error.response?.data?.responseMessage);
          setTotalElements(0);
          setReturnStatusCounts({
            IN_PROCESS: 0,
            PASSED_QC: 0,
            FAILED_QC: 0
          });
        });
    },
    [organisationName, branchName, searchTerm]
  );

  useEffect(() => {
    if (searchTerm) {
      searchReturnRequests(searchPageIndex, pageSize);
    }
  }, [searchTerm, searchReturnRequests, searchPageIndex, pageSize]);

  useEffect(() => {
    if (!searchTerm && startDate && endDate) {
      const formattedStart = startDate.toLocaleDateString();
      const formattedEnd = endDate.toLocaleDateString();
      setDropdownLabel(
        startDate.toDateString() === endDate.toDateString()
          ? formattedStart
          : `${formattedStart} - ${formattedEnd}`
      );
      fetchReturnServices(startDate, endDate);
    }
  }, [searchTerm, startDate, endDate, fetchReturnServices]);

  const handleDateChange = update => {
    const [start, end] = update;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      const formattedStart = start.toLocaleDateString();
      const formattedEnd = end.toLocaleDateString();
      setDropdownLabel(`${formattedStart} - ${formattedEnd}`);
      setShowCalendar(false);
    }
  };

  const clearSearch = () => {
    setSearchTerm('');
  };
  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };
  const navigate = useNavigate();
  const handlenavigate = () => {
    navigate('/new-return-tracker');
  };
  const handleSortClick = () => {
    setShowCalendar(false);
  };
  const formatDate = dateString => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}
      <div className="d-flex justify-content-end mt-15">
        <Profile />
      </div>
      <div className="count-container d-flex justify-content-evenly align-items-center mt-5">
        <div className="text-center d-flex count-containers">
          <div className="process-icon">
            <FaClock className="clock-icon fs-2" />
          </div>
          <div className="ms-3">
            <p className="count-head fs-14">In Process</p>
            <p className="fw-bold count-number-style">
              {returnStatusCounts.IN_PROCESS}
            </p>
          </div>
        </div>
        <div className="text-center d-flex count-containers">
          <div className="passed-icon">
            <FaCheckCircle className="tick-icon fs-2" />
          </div>
          <div className="ps-3">
            <p className="count-head fs-14">Passed QC</p>
            <p className="fw-bold count-number-style">
              {returnStatusCounts.PASSED_QC}
            </p>
          </div>
        </div>
        <div className="text-center d-flex count-containers">
          <div className="failed-icon">
            <FaTimesCircle className="fs-2 wrong-icon" />
          </div>
          <div className="ps-3">
            <p className="count-head fs-14">Failed QC</p>
            <p className="fw-bold count-number-style">
              {returnStatusCounts.FAILED_QC}
            </p>
          </div>
        </div>
      </div>
      <div className="card-all-return-requests p-4">
        <p className="fs-22 fw-600">All Return Requests</p>
        <Flex className="mt-3 mb-3 align-items-center justify-content-end">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search"
            className="w-25"
            id="return-tracker-home-search-input"
          />
          {!searchTerm && (
            <div className="d-flex justify-content-end align-items-center">
              <div className="dropdown-select d-flex align-items-center">
                <button
                  onClick={handleSortClick}
                  className="fs-14 fw-500 grayish-blue pe-2 text-nowrap btn-no-style"
                >
                  Sort by:
                </button>
                <Calendar
                  startDate={formatLocalDate(startDate)}
                  endDate={formatLocalDate(endDate)}
                  onDateChange={handleDateChange}
                  dropdownLabel={dropdownLabel}
                  showCalendar={showCalendar}
                  setShowCalendar={setShowCalendar}
                />
              </div>
            </div>
          )}
        </Flex>
        <Flex className="align-items-center justify-content-end ">
          <button
            type="button"
            className="btn btn-outline custom-btn-outline mb-3"
            onClick={handlenavigate}
          >
            + Create a new Return Tracker
          </button>
        </Flex>
        <Table>
          <thead>
            <tr>
              <th>Serial Number</th>
              <th>Company</th>
              <th>Model Name</th>
              <th>Customer name</th>
              <th>Created</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {errorMessage ? (
              <tr>
                <td colSpan="7" className="text-center">
                  <div className="text-center text-danger">{errorMessage}</div>
                </td>
              </tr>
            ) : (
              returnRequests.map(request => {
                return (
                  <tr key={request.serialNumber}>
                    <td>{request.serialNumber}</td>
                    <td className="ps-3">{request.tyreManufacturer}</td>
                    <td className="limited-text ps-3">{request.tyreModel}</td>
                    <td>
                      <Link
                        to={`/customer-returns-counts/${request.customerName}`}
                        style={{
                          color: '#127BFF',
                          textDecoration: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        {request.customerName}
                      </Link>
                    </td>
                    <td>{formatDate(request.created.createdTime)}</td>
                    <td className="ps-3">
                      <SubtleBadge
                        bg={statusByColor(request.returnStatus)}
                        className="fs-14 fw-500"
                      >
                        {toPascalWord(request.returnStatus)}
                      </SubtleBadge>
                    </td>
                    <td>
                      <Link
                        to={`/edit-return-tracker/${request.serialNumber}`}
                        style={{
                          color: '#127BFF',
                          textDecoration: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        Edit
                      </Link>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        <div className="mt-4">
          <ViewAll
            totalItems={totalElements}
            to={`/view-all-return-requests/${formatLocalDate(startDate)}/${formatLocalDate(endDate)}`}
          />
        </div>
      </div>
    </div>
  );
};
export default ReturnTrackerHome;
