import Tabs from 'react-bootstrap/Tabs';
import React, { useCallback, useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import ManageUsers from '../admin-manager-access/manageUsers';
import ManageOrganisation from '../admin-manager-access/manageOrganisation';
import Settings from './settings';
import Flex from '../../common/flex';
import Profile from '../../common/profile';
import DataTransferService from '../../core/service/dataTransferService';
import { ReactTabsStylings } from '../../core/interface';
import Vehicles from './vehicles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faCar,
  faUser,
  faUserFriends
} from '@fortawesome/free-solid-svg-icons';

const UpdateProfile = () => {
  const [activeTab, setActiveTab] = useState('settings');
  const dataTransferService = new DataTransferService();
  const userRole = dataTransferService.getUserInfo().userRole;
  const [allBranchesReport, setAllBranchesReport] = useState(true);
  const handleTabChange = tabKey => {
    setActiveTab(tabKey);
  };

  const getSelectedBranch = useCallback(() => {
    const dataTransferService = new DataTransferService();
    if (dataTransferService.getSelectedBranch() !== 'All Branches Report') {
      setAllBranchesReport(false);
    }
  }, []);

  useEffect(() => {
    getSelectedBranch();
  }, [getSelectedBranch]);

  return (
    <div className="background-container p-4">
      <div className="d-flex justify-content-between">
        <p className="fw-500 fs-4 mt-21">Settings</p>
        <Flex className="d-flex justify-content-end align-items-center pt-1">
          <Profile />
        </Flex>
      </div>

      <div className="user-profile-container p-4">
        <div>
          <Tabs
            activeKey={activeTab}
            onSelect={handleTabChange}
            className="custom-tabs tabs-margin fs-6"
          >
            {/* Settings Tab */}
            <Tab
              eventKey="settings"
              title={
                <span
                  style={{
                    ...ReactTabsStylings.tabTitle,
                    ...(activeTab === 'settings'
                      ? ReactTabsStylings.activeTab
                      : ReactTabsStylings.inactiveTab)
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    className="icon-size-profile"
                  />{' '}
                  Account Information
                </span>
              }
            >
              <Settings />
            </Tab>

            {/* Conditionally render Manage Organisation tab */}
            {(userRole === 'ADMIN' || userRole === 'MANAGER') &&
              !allBranchesReport && (
                <Tab
                  eventKey="manageOrganisation"
                  title={
                    <span
                      style={{
                        ...ReactTabsStylings.tabTitle,
                        ...(activeTab === 'manageOrganisation'
                          ? ReactTabsStylings.activeTab
                          : ReactTabsStylings.inactiveTab)
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBuilding}
                        className="icon-size-profile"
                      />{' '}
                      Manage Organisations
                    </span>
                  }
                >
                  <ManageOrganisation />
                </Tab>
              )}

            {/* Conditionally render Manage Users tab */}
            {(userRole === 'ADMIN' || userRole === 'MANAGER') &&
              !allBranchesReport && (
                <Tab
                  eventKey="manageUsers"
                  title={
                    <span
                      style={{
                        ...ReactTabsStylings.tabTitle,
                        ...(activeTab === 'manageUsers'
                          ? ReactTabsStylings.activeTab
                          : ReactTabsStylings.inactiveTab)
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faUserFriends}
                        className="icon-size-profile"
                      />{' '}
                      Manage Users
                    </span>
                  }
                >
                  <ManageUsers />
                </Tab>
              )}

            <Tab
              eventKey="vehicles"
              title={
                <span
                  style={{
                    ...ReactTabsStylings.tabTitle,
                    ...(activeTab === 'vehicles'
                      ? ReactTabsStylings.activeTab
                      : ReactTabsStylings.inactiveTab)
                  }}
                >
                  <FontAwesomeIcon icon={faCar} className="icon-size-profile" />
                  Vehicles
                </span>
              }
            >
              <Vehicles />
            </Tab>
          </Tabs>
        </div>
        <hr className="color" />
      </div>
    </div>
  );
};

export default UpdateProfile;
