import React, { useState, useEffect, useCallback } from 'react';
import Profile from '../../common/profile';
import Loader from '../../core/loader/loader';
import { PaginationComponent } from '../../common/pagination';
import ServiceRequest from '../../core/service/serviceRequest';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import { Link } from 'react-router-dom';
import Flex from '../../common/flex';
import DataTransferService from '../../core/service/dataTransferService';
import SearchInput from '../../common/search';
import { Back } from '../../common/back';
import { numberFormatter } from '../../common/numberFormatter';
import Calendar from '../../common/calender';
import { formatLocalDate } from '../../common/shared';

const ServiceAlerts = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize] = useState(10);
  const [errorMessage, setErrorMessage] = useState('');
  const dataTransferService = new DataTransferService();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const [showCalendar, setShowCalendar] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState('Calender');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [searchPageIndex, setSearchPageIndex] = useState(0);

  const fetchServicesWithDate = useCallback(
    (startDate, endDate) => {
      setLoading(true);
      const serviceRequest = new ServiceRequest();
      serviceRequest
        .fetchServicesWithDate(
          organisationName,
          branchName,
          pageIndex,
          pageSize,
          formatLocalDate(startDate),
          formatLocalDate(endDate)
        )
        .then(response => {
          if (response?.data?.responseCode === 200) {
            setSearchPageIndex(0);
            setErrorMessage('');
            setServicesData(response.data.responseBody.services);
            setTotalItems(response.data.responseBody.TotalItems);
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          handleErrorResponse('fetchServicesWithDate', error.response);
          setErrorMessage(error.response?.data?.responseMessage);
          setTotalItems(0);
          setSearchPageIndex(0);
        });
    },
    [pageIndex, pageSize, branchName, organisationName]
  );

  const searchService = useCallback(() => {
    setLoading(true);
    setErrorMessage('');
    const serviceRequest = new ServiceRequest();
    const dataTransferService = new DataTransferService();
    serviceRequest
      .searchServices(
        searchTerm,
        searchPageIndex,
        pageSize,
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization()
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setServicesData(response.data.responseBody.services);
          setTotalItems(response.data.responseBody.TotalItems);
          setLoading(false);
          setPageIndex(0);
        }
      })
      .catch(error => {
        setPageIndex(0);
        setLoading(false);
        handleErrorResponse('searchServices', error.response);
        setErrorMessage(error.response.data.responseMessage);
        setTotalItems(0);
      });
  }, [searchTerm, pageSize, searchPageIndex]);

  useEffect(() => {
    if (searchTerm) {
      searchService();
    }
  }, [searchTerm, searchService]);

  useEffect(() => {
    if (!searchTerm && startDate && endDate) {
      fetchServicesWithDate(startDate, endDate);
    }
  }, [startDate, endDate, searchTerm, fetchServicesWithDate]);

  const handleDateChange = update => {
    const [start, end] = update;
    setStartDate(start);
    setPageIndex(0);
    setSearchPageIndex(0);

    if (end) {
      const currentEndDate = new Date(end);
      currentEndDate.setHours(23, 59, 59, 999);
      setEndDate(currentEndDate);

      const formattedStart = start?.toLocaleDateString();
      const formattedEnd = currentEndDate.toLocaleDateString();
      setDropdownLabel(`${formattedStart} - ${formattedEnd}`);
      setShowCalendar(false);
      setPageIndex(0);
    } else {
      setEndDate(null);
    }
  };

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };
  const handleSortClick = () => {
    setShowCalendar(false);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setPageIndex(0);
  };
  const prevPage = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (pageIndex < Math.ceil(totalItems / pageSize) - 1)
      setPageIndex(pageIndex + 1);
  };

  const onPageChange = newPageIndex => {
    setPageIndex(newPageIndex);
  };

  const searchNextPage = () => {
    if (searchPageIndex < Math.ceil(totalItems / pageSize) - 1)
      setSearchPageIndex(searchPageIndex + 1);
  };

  const searchPrevPage = () => {
    if (searchPageIndex > 0) setSearchPageIndex(searchPageIndex - 1);
  };

  const onSearchPageChange = newPageIndex => {
    setSearchPageIndex(newPageIndex);
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <Flex className="d-flex align-items-center justify-content-end mt-15">
        <Profile />
      </Flex>

      <div className="mt-5 p-4 select-vehicle-container mt-5">
        <Flex className="align-items-center justify-content-start">
          <Back />
          <p className="fs-22 fw-600 ps-2">Service Alerts</p>
        </Flex>

        <Flex className="pt-3 align-items-center justify-content-end">
          <SearchInput
            value={searchTerm}
            onChange={handleSearchChange}
            clearSearch={clearSearch}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            placeholder="Search"
            className="w-25"
            id="service-alerts-search-input"
          />
          {!searchTerm && (
            <div className="d-flex justify-content-end align-items-center">
              <div className="dropdown-select d-flex align-items-center">
                <button
                  onClick={handleSortClick}
                  className="fs-14 fw-500 grayish-blue pe-2 text-nowrap btn-no-style"
                >
                  Sort by:
                </button>
                <Calendar
                  startDate={formatLocalDate(startDate)}
                  endDate={formatLocalDate(endDate)}
                  onDateChange={handleDateChange}
                  dropdownLabel={dropdownLabel}
                  showCalendar={showCalendar}
                  setShowCalendar={setShowCalendar}
                />
              </div>
            </div>
          )}
        </Flex>

        <table className="table mt-5">
          <thead>
            <tr className="fs-14 fw-500">
              <th className="grayish-blue">Vehicle Number</th>
              <th className="grayish-blue">Category</th>
              <th className="grayish-blue">Customer Name</th>
              <th className="grayish-blue">Mobile Number</th>
              <th className="grayish-blue">Payment Type</th>
              <th className="grayish-blue">Actions</th>
            </tr>
          </thead>
          <tbody>
            {errorMessage ? (
              <tr>
                <td colSpan="5" className="text-center">
                  <div className="text-center text-danger">{errorMessage}</div>
                </td>
              </tr>
            ) : (
              servicesData.map(service => (
                <tr key={service.invoiceSerialNumber}>
                  <td style={{ padding: '1rem' }}>
                    <Link
                      to={`/alerts-history/${
                        service?.invoiceData?.vehicleNumber ||
                        service?.vehicleNumber ||
                        ''
                      }`}
                      style={{ color: '#127BFF', textDecoration: 'none' }}
                    >
                      {service?.invoiceData?.vehicleNumber ||
                        service?.vehicleNumber ||
                        'N/A'}
                    </Link>
                  </td>
                  <td style={{ padding: '1rem' }}>{service.vehicleType}</td>
                  <td style={{ padding: '1rem' }}>
                    {service.invoiceData?.customerName || service?.customerName}
                  </td>
                  <td style={{ padding: '1rem' }}>
                    {service.invoiceData?.customerMobileNumber ||
                      service?.customerMobileNumber}
                  </td>
                  <td style={{ padding: '1rem' }}>
                    {service.invoiceData?.paymentType ||
                      service.paymentType ||
                      'N/A'}
                  </td>
                  <td style={{ padding: '1rem' }}>
                    <Link
                      to={`/invoice/${service.invoiceData?.invoiceSerialNumber || service?.invoiceSerialNumber}`}
                      style={{ color: '#127BFF', textDecoration: 'none' }}
                    >
                      View
                    </Link>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        <Flex className="mt-4 justify-content-between align-items-center fs-14 fw-500 grayish-blue">
          {!searchTerm ? (
            <span>
              {totalItems > 0
                ? `Showing ${pageIndex * pageSize + 1} to ${Math.min(
                    (pageIndex + 1) * pageSize,
                    totalItems
                  )} of ${numberFormatter(totalItems)} entries`
                : 'No entries available'}
            </span>
          ) : (
            <span>
              {totalItems > 0
                ? `Showing ${searchPageIndex * pageSize + 1} to ${Math.min(
                    (searchPageIndex + 1) * pageSize,
                    totalItems
                  )} of ${numberFormatter(totalItems)} entries`
                : 'No entries available'}
            </span>
          )}
          {!searchTerm ? (
            <PaginationComponent
              prevPage={prevPage}
              nextPage={nextPage}
              isNextDisabled={pageIndex >= Math.ceil(totalItems / pageSize) - 1}
              isPrevDisabled={pageIndex === 0}
              pageIndex={pageIndex}
              totalItems={totalItems}
              pageSize={pageSize}
              onPageChange={onPageChange}
            />
          ) : (
            <PaginationComponent
              prevPage={searchPrevPage}
              nextPage={searchNextPage}
              isNextDisabled={
                searchPageIndex >= Math.ceil(totalItems / pageSize) - 1
              }
              isPrevDisabled={searchPageIndex === 0}
              pageIndex={searchPageIndex}
              totalItems={totalItems}
              pageSize={pageSize}
              onPageChange={onSearchPageChange}
            />
          )}
        </Flex>
      </div>
    </div>
  );
};

export default ServiceAlerts;
