import React, { useEffect, useState, useRef, useCallback } from 'react';
import DataTransferService from '../../core/service/dataTransferService';
import Profile from '../../common/profile';
import { DropdownComponent } from '../../common/dropDown';
import Flex from '../../common/flex';
import { Table } from 'react-bootstrap';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import ServiceRequest from '../../core/service/serviceRequest';
import Loader from '../../core/loader/loader';
import { Link, useNavigate } from 'react-router-dom';
import SearchInput from '../../common/search';
import ViewAll from '../../common/viewAll';
import { TableHeaderComponent } from '../../common/table';
import AllBranchesReport from '../vehicle-data/allBranchesReport';
import Calendar from '../../common/calender';
import { formatLocalDate } from '../../common/shared';

const Billing = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [loading, setLoading] = useState(false);
  const [allBranchesReport, setAllBranchesReport] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [servicesData, setServicesData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [cashData, setCashData] = useState('');
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const isFirstRender = useRef(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState('Calender');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const columnNames = [
    'Vehicle Number',
    'Invoice Number',
    'Customer Name',
    'Category',
    'Amount',
    'Payment Type',
    'Invoice'
  ];

  const fetchServicesWithDate = useCallback((startDate, endDate) => {
    setLoading(true);
    const serviceRequest = new ServiceRequest();
    const dataTransferService = new DataTransferService();

    const organization = dataTransferService.getSelectedOrganization();
    const branch = dataTransferService.getSelectedBranch();
    const formattedStartDate = formatLocalDate(startDate);
    const formattedEndDate = formatLocalDate(endDate);

    const fetchServicesPromise = serviceRequest.fetchServicesWithDate(
      organization,
      branch,
      0,
      10,
      formattedStartDate,
      formattedEndDate
    );

    const amountTotalPromise = serviceRequest.amountTotal(
      organization,
      branch,
      formattedStartDate,
      formattedEndDate
    );

    Promise.all([fetchServicesPromise, amountTotalPromise])
      .then(([servicesResponse, amountResponse]) => {
        if (servicesResponse?.data?.responseCode === 200) {
          setErrorMessage('');
          setServicesData(servicesResponse.data.responseBody.services);
          setTotalItems(servicesResponse.data.responseBody.TotalItems);
        }

        if (amountResponse?.data?.responseCode === 200) {
          setErrorMessage('');
          setCashData(amountResponse.data.responseBody);
        }
        setLoading(false);
      })
      .catch(error => {
        setErrorMessage('No services found.');
        setLoading(false);
        handleErrorResponse('fetchServicesWithDate', error.response);
        setErrorMessage(error.response?.data?.responseMessage);
        setCashData('');
        setTotalItems(0);
      });
  }, []);

  const searchService = useCallback(() => {
    setLoading(true);
    setErrorMessage('');
    const serviceRequest = new ServiceRequest();
    const dataTransferService = new DataTransferService();
    serviceRequest
      .searchServices(
        searchTerm,
        0,
        10,
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization()
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setServicesData(response.data.responseBody.services);
          setTotalItems(response.data.responseBody.TotalItems);
          setLoading(false);
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('searchServices', error.response);
        setErrorMessage(error.response.data.responseMessage);
      });
  }, [searchTerm]);

  useEffect(() => {
    const dataTransferService = new DataTransferService();
    const storedBranch = dataTransferService.getSelectedBranch();
    const branList = dataTransferService.getListOfBranches();
    if (branList) {
      const parsedBranches = branList.split(',');
      setBranches(parsedBranches);
      if (storedBranch) {
        setSelectedBranch(storedBranch);
        dataTransferService.setSelectedBranch(storedBranch);
      } else if (parsedBranches.length > 0) {
        const initialBranch = parsedBranches[0].trim();
        setSelectedBranch(initialBranch);
        dataTransferService.setSelectedBranch(initialBranch);
      }
    }
  }, []);

  useEffect(() => {
    const dataTransferService = new DataTransferService();

    if (isFirstRender.current) {
      isFirstRender.current = false;
    }

    if (searchTerm) {
      searchService();
      return;
    }

    if (startDate && endDate) {
      const formattedStart = startDate.toLocaleDateString();
      const formattedEnd = endDate.toLocaleDateString();
      setDropdownLabel(
        startDate.toDateString() === endDate.toDateString()
          ? formattedStart
          : `${formattedStart} - ${formattedEnd}`
      );

      if (dataTransferService.getSelectedBranch() !== 'All Branches Report') {
        setAllBranchesReport(false);
        fetchServicesWithDate(startDate, endDate);
        return;
      }
    }

    if (dataTransferService.getSelectedBranch() === 'All Branches Report') {
      setAllBranchesReport(true);
    }
  }, [
    searchTerm,
    startDate,
    endDate,
    fetchServicesWithDate,
    searchService,
    setDropdownLabel
  ]);

  const handleDateChange = update => {
    const [start, end] = update;
    setStartDate(start);

    if (end) {
      const currentEndDate = new Date(end);
      currentEndDate.setHours(23, 59, 59, 999);
      setEndDate(currentEndDate);
      const formattedStart = start?.toLocaleDateString();
      const formattedEnd = currentEndDate.toLocaleDateString();
      setDropdownLabel(`${formattedStart} - ${formattedEnd}`);
      setShowCalendar(false);
    } else {
      setEndDate(null);
    }
  };

  const handleBranchSelect = branch => {
    const trimmedBranch = branch.trim();
    setSelectedBranch(trimmedBranch);
    dataTransferService.setSelectedBranch(trimmedBranch);
    window.location.reload();
  };
  const dataTransferService = new DataTransferService();
  const userRole = dataTransferService.getUserInfo().userRole;
  const branchOptions = [
    ...(userRole === 'ADMIN' ||
    userRole === 'MANAGER' ||
    userRole === 'STORE_SUPERVISOR'
      ? [
          {
            id: -1,
            value: 'All Branches Report',
            label: 'All Branches Report'
          }
        ]
      : []),
    ...branches.map((branch, index) => ({
      id: index,
      value: branch.trim(),
      label: branch.trim()
    }))
  ];

  const handleSearchChange = e => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const handlenavigate = () => {
    navigate('/select-vehicle');
  };

  const navigateToCreditHistory = () => {
    navigate(
      `/show-credit-history/${formatLocalDate(startDate)}/${formatLocalDate(endDate)}`
    );
  };

  const handleSortClick = () => {
    setShowCalendar(false);
  };

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <Flex className="d-flex justify-content-between align-items-center mt-15">
        <DropdownComponent
          value={selectedBranch}
          selected={selectedBranch}
          options={branchOptions}
          onSelect={handleBranchSelect}
          toggleClassName="fs-4 fw-500 branch-change-dropdown custom-arrow"
        />
        <Profile />
      </Flex>

      {!allBranchesReport && (
        <>
          <div className="row mb-3 mt-5">
            <div className="col-4">
              <div className="h-100 p-4 shadow-sm card-radius card-bg-color">
                <h5 className="text-color">Cash Transactions</h5>
                <hr />
                <h3 className="fs-3 fw-bold rupee-style">
                  {`₹ ${Number(cashData?.totalCashAmount || 0).toFixed(0)}`}
                </h3>

                <button
                  className="btn-no-style link-blue pt-2"
                  style={{ visibility: 'hidden' }}
                  onClick={navigateToCreditHistory}
                >
                  View all →
                </button>
              </div>
            </div>

            <div className="col-4">
              <div className="h-100 p-4 shadow-sm card-radius card-bg-color">
                <h5 className="text-color">Credit Transactions</h5>
                <hr />
                <h3 className="fs-3 fw-bold rupee-style">{`₹ ${Number(
                  cashData?.totalCreditAmount || 0
                ).toFixed(0)}`}</h3>
                <button
                  className="btn-no-style link-blue pt-2"
                  onClick={navigateToCreditHistory}
                >
                  View all →
                </button>
              </div>
            </div>

            <div className="col-4">
              <div className="h-100 p-4 shadow-sm card-radius card-bg-color">
                <h5 className="text-color">UPI Transactions</h5>
                <hr />
                <h3 className="fs-3 fw-bold rupee-style">{`₹ ${Number(
                  cashData?.totalUpiAmount || 0
                ).toFixed(0)}`}</h3>
                <button
                  className="btn-no-style link-blue pt-2"
                  style={{ visibility: 'hidden' }}
                  onClick={navigateToCreditHistory}
                >
                  View all →
                </button>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <div className="h-100 p-4 shadow-sm card-radius card-bg-color">
                <h5 className="text-color">Credit Card Transactions</h5>
                <hr />
                <h3 className="fs-3 fw-bold rupee-style">{`₹ ${Number(
                  cashData?.totalCreditCardAmount || 0
                ).toFixed(0)}`}</h3>
                <button
                  className="btn-no-style link-blue pt-2"
                  style={{ visibility: 'hidden' }}
                  onClick={navigateToCreditHistory}
                >
                  View all →
                </button>
              </div>
            </div>
            <div className="col-4">
              <div className="h-100 p-4 shadow-sm card-radius card-bg-color">
                <h5 className="text-color">Vehicles Serviced</h5>
                <hr />
                <h3 className="fs-3 fw-bold rupee-style">{` ${Number(
                  cashData?.totalVehicleCount || 0
                ).toFixed(0)}`}</h3>
                <button
                  className="btn-no-style link-blue pt-2"
                  style={{ visibility: 'hidden' }}
                  onClick={navigateToCreditHistory}
                >
                  View all →
                </button>
              </div>
            </div>
            <div className="col-4">
              <div className="h-100 p-4 shadow-sm card-radius card-bg-color">
                <h5 className="text-color">Wheel Aligned Vehicles</h5>
                <hr />
                <h3 className="fs-3 fw-bold rupee-style">{` ${Number(
                  cashData?.totalWheelAlignment || 0
                ).toFixed(0)}`}</h3>
                <button
                  className="btn-no-style link-blue pt-2"
                  style={{ visibility: 'hidden' }}
                  onClick={navigateToCreditHistory}
                >
                  View all →
                </button>
              </div>
            </div>
          </div>

          <div className="white-container mt-3 services-card-radius p-4">
            <h3 className="padding-heading fw-600">Recent Services</h3>
            <div className="d-flex justify-content-end mt-2">
              <SearchInput
                value={searchTerm}
                onChange={handleSearchChange}
                clearSearch={clearSearch}
                isFocused={isFocused}
                setIsFocused={setIsFocused}
                placeholder="Search"
                className="w-25"
                id="billing-search-input"
              />
              {!searchTerm && (
                <div className="d-flex justify-content-end align-items-center">
                  <div className="dropdown-select d-flex align-items-center">
                    <button
                      onClick={handleSortClick}
                      className="fs-14 fw-500 grayish-blue pe-2 text-nowrap btn-no-style"
                    >
                      Sort by:
                    </button>
                    <Calendar
                      startDate={formatLocalDate(startDate)}
                      endDate={formatLocalDate(endDate)}
                      onDateChange={handleDateChange}
                      dropdownLabel={dropdownLabel}
                      showCalendar={showCalendar}
                      setShowCalendar={setShowCalendar}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex justify-content-end me-2 mt-3">
              <button
                className="btn btn-outline custom-btn-outline"
                onClick={handlenavigate}
              >
                + New Service
              </button>
            </div>

            <div className="table-responsive">
              <Table className="table">
                <thead>
                  <TableHeaderComponent columnNames={columnNames} />
                </thead>
                <tbody>
                  {errorMessage ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        <div className="text-center text-danger">
                          {errorMessage}
                        </div>
                      </td>
                    </tr>
                  ) : (
                    servicesData.map(service => (
                      <tr key={service.invoiceSerialNumber}>
                        <td>{service.vehicleNumber} </td>
                        <td>{service.invoiceSerialNumber}</td>
                        <td> {service.customerName}</td>
                        <td>{service.vehicleType}</td>
                        <td>{`Rs.${Number(service.amount).toFixed(0)}`}</td>
                        <td>{service.paymentType || 'N/A'}</td>

                        <td>
                          <Link
                            to={`/invoice/${service.invoiceSerialNumber}`}
                            style={{ color: '#127BFF', textDecoration: 'none' }}
                          >
                            View
                          </Link>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </div>

            <div className="mt-3">
              <ViewAll
                totalItems={totalItems}
                to={`/recent-services/${formatLocalDate(startDate)}/${formatLocalDate(endDate)}`}
              />
            </div>
          </div>
        </>
      )}

      {allBranchesReport && <AllBranchesReport />}
    </div>
  );
};

export default Billing;
