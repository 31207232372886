import { Formik, Form, Field, ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react';
import '../../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { heavyVehicleBillingValidations } from '../../validations/forms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import DataTransferService from '../../core/service/dataTransferService';
import Profile from '../../common/profile';
import Flex from '../../common/flex';
import InvoiceService from '../../core/service/invoiceService';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import { toast } from 'react-toastify';
import Loader from '../../core/loader/loader';
import { useNavigate } from 'react-router-dom';
import {
  InputField,
  SelectField,
  ServiceField
} from '../../validations/inputFields';
import { Back } from '../../common/back';
import { toPascalCase } from '../../common/shared';
import {
  vehicleModel,
  findVehicle,
  getVehicleManufacturers,
  findCustomer
} from '../../common/commonApis';

const HeavyVehicleBilling = () => {
  const vehicleType = 'TRUCK';
  const [disableFields, setDisableFields] = useState(false);
  const initialValues = {
    services: {
      wheelAlignment: {
        selected: false,
        wheelAlignmentPrice: ''
      },
      wheelBalancing: {
        selected: false,
        wheelBalancingQuantity: '',
        wheelBalancingPrice: ''
      },
      weightBalancing: {
        selected: false,
        weightBalancingQuantity: '',
        weightBalancingPrice: ''
      },
      tyreChanging: {
        selected: false,
        tyreChangingQuantity: '',
        tyreChangingPrice: ''
      },
      tyreRotation: {
        selected: false,
        tyreRotationQuantity: '',
        tyreRotationPrice: ''
      },
      tyrePuncture: {
        selected: false,
        tyrePunctureQuantity: '',
        tyrePuncturePrice: ''
      },
      sims: {
        selected: false,
        simsQuantity: '',
        simsPrice: ''
      },
      nitrogen: {
        selected: false,
        nitrogenQuantity: '',
        nitrogenPrice: ''
      },
      others: {
        selected: false,
        othersQuantity: '',
        othersPrice: ''
      },
      freight: {
        selected: '',
        freightCharge: ''
      }
    },
    customerName: '',
    customerMobileNumber: '',
    driverMobileNumber: '',
    vehicleNumber: '',
    vehicleManufacturer: '',
    numberOfVehicleWheels: '',
    vehicleWheels: '',
    vehicleModel: '',
    vehicleOdometerReading: '',
    vehicleAverageKmsPerDay: '',
    createdOn: '',
    useGstInvoice: false,
    gstinNumber: '',
    businessName: ''
  };
  const [customerDisableFields, setCustomerDisableFields] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const invoiceService = new InvoiceService();
  const dataTransferService = new DataTransferService();
  const [currentDate, setCurrentDate] = useState('');
  const [responseVehicleModel, setResponseVehicleModel] = useState('');
  const [vehicleManfacturerResponse, setVehicleManfacturerResponse] = useState(
    []
  );
  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setCurrentDate(formattedDate);
    getVehicleManufacturers(vehicleType, {
      setLoading,
      setVehicleManfacturerResponse
    });
  }, [vehicleType]);

  const handleSubmit = values => {
    const selectedServices = [
      values.services.wheelAlignment.selected,
      values.services.wheelBalancing.selected,
      values.services.weightBalancing.selected,
      values.services.tyreChanging.selected,
      values.services.tyreRotation.selected,
      values.services.tyrePuncture.selected,
      values.services.sims.selected,
      values.services.nitrogen.selected,
      values.services.others.selected
    ];
    const isAnyServiceSelected = selectedServices.some(service => service);

    if (!isAnyServiceSelected) {
      toast.error('Please select at least one service.', {
        theme: 'colored'
      });
      return;
    }

    const formattedData = {
      customerDTO: {
        customerMobileNumber: values.customerMobileNumber,
        customerName: values.customerName.trim().replace(/\s+/g, ' ')
      },
      ...((values.businessName || values.gstinNumber) && {
        businessDetailsDTO: {
          businessName: values.businessName.trim().replace(/\s+/g, ' '),
          gstinNumber: values.gstinNumber.trim().replace(/\s+/g, ' ')
        }
      }),
      vehicleDTO: {
        vehicleNumber: values.vehicleNumber.trim().replace(/\s+/g, ' '),
        vehicleManufacturer: values.vehicleManufacturer.toUpperCase(),
        vehicleModel: values.vehicleModel.toUpperCase(),
        vehicleOdometerReading: values.vehicleOdometerReading,
        vehicleAverageKmsPerDay: values.vehicleAverageKmsPerDay,
        driverMobileNumber: values.driverMobileNumber,
        vehicleWheels: Number(values.numberOfVehicleWheels),
        vehicleType: 'TRUCK'
      },
      servicesDTO: {
        ...(values.services.wheelAlignment.selected && {
          wheelAlignment: values.services.wheelAlignment.selected,
          wheelAlignmentPrice:
            values.services.wheelAlignment.wheelAlignmentPrice
        }),
        ...(values.services.wheelBalancing.selected && {
          wheelBalancing: values.services.wheelBalancing.selected,
          wheelBalancingQuantity:
            values.services.wheelBalancing.wheelBalancingQuantity,
          wheelBalancingPrice:
            values.services.wheelBalancing.wheelBalancingPrice
        }),
        ...(values.services.weightBalancing.selected && {
          weightBalancing: values.services.weightBalancing.selected,
          weightBalancingQuantity:
            values.services.weightBalancing.weightBalancingQuantity,
          weightBalancingPrice:
            values.services.weightBalancing.weightBalancingPrice
        }),
        ...(values.services.tyreChanging.selected && {
          tyreChanging: values.services.tyreChanging.selected,
          tyreChangingQuantity:
            values.services.tyreChanging.tyreChangingQuantity,
          tyreChangingPrice: values.services.tyreChanging.tyreChangingPrice
        }),
        ...(values.services.tyreRotation.selected && {
          tyreRotation: values.services.tyreRotation.selected,
          tyreRotationQuantity:
            values.services.tyreRotation.tyreRotationQuantity,
          tyreRotationPrice: values.services.tyreRotation.tyreRotationPrice
        }),
        ...(values.services.tyrePuncture.selected && {
          tyrePuncture: values.services.tyrePuncture.selected,
          tyrePunctureQuantity:
            values.services.tyrePuncture.tyrePunctureQuantity,
          tyrePuncturePrice: values.services.tyrePuncture.tyrePuncturePrice
        }),
        ...(values.services.sims.selected && {
          sims: values.services.sims.selected,
          simsQuantity: values.services.sims.simsQuantity,
          simsPrice: values.services.sims.simsPrice
        }),
        ...(values.services.nitrogen.selected && {
          nitrogen: values.services.nitrogen.selected,
          nitrogenQuantity: values.services.nitrogen.nitrogenQuantity,
          nitrogenPrice: values.services.nitrogen.nitrogenPrice
        }),
        ...(values.services.others.selected && {
          others: values.services.others.selected,
          othersDescription: values.services.others.othersQuantity,
          othersPrice: values.services.others.othersPrice
        }),
        ...(values.services.freight.selected === 'Yes' && {
          freight: true,
          freightCharge: values.services.freight.freightCharge || 0
        })
      }
    };
    submitForm(formattedData);
  };

  const submitForm = formattedData => {
    setLoading(true);
    invoiceService
      .generateheavyWheelerInvoice(
        dataTransferService.getSelectedBranch(),
        dataTransferService.getSelectedOrganization(),
        formattedData
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          navigate(`/invoice/${response.data.responseBody}`);
          toast.success('Invoice Generated successfully', {
            theme: 'colored'
          });
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('generate-invoice', error.response);
      });
  };

  return (
    <div className="whole-container p-4">
      {loading && <Loader loading={loading} />}

      <Flex className="d-flex justify-content-end align-items-center mt-15">
        <Profile />
      </Flex>

      <div className="box-container p-4 d-flex justify-content-start flex-direction-column">
        <div className="d-flex align-items-center">
          <Back />
          <p className="ps-2 fs-4 fw-600">Bill New Service</p>
        </div>

        <hr className="mt-3" />

        <Formik
          initialValues={initialValues}
          validationSchema={heavyVehicleBillingValidations}
          onSubmit={handleSubmit}
          validateOnChange={true}
        >
          {formik => (
            <Form>
              <div className="row mt-3">
                <div className="col">
                  <InputField
                    label="Vehicle Number"
                    name="vehicleNumber"
                    type="text"
                    placeholder="Enter Vehicle Number"
                    className="form-control w-75"
                    onBlur={e => {
                      const vehicleNumber = e.target.value;
                      formik.setFieldValue('vehicleNumber', vehicleNumber);
                      findVehicle(vehicleNumber, {
                        setLoading,
                        vehicleType,
                        formik,
                        setDisableFields
                      });
                    }}
                  />
                </div>
                <div className="col">
                  <SelectField
                    label="Vehicle Manufacturer"
                    name="vehicleManufacturer"
                    options={vehicleManfacturerResponse}
                    className="w-75"
                    onChange={e => {
                      const selectedManufacturer = e.target.value;
                      selectedManufacturer.toUpperCase();
                      formik.setFieldValue(
                        'vehicleManufacturer',
                        selectedManufacturer
                      );
                      vehicleModel(vehicleType, selectedManufacturer, {
                        setLoading,
                        setResponseVehicleModel
                      });
                    }}
                    disabled={disableFields}
                  />
                </div>
              </div>

              <div className="row pt-3">
                <div className="col">
                  <InputField
                    label="Customer Mobile Number"
                    name="customerMobileNumber"
                    type="text"
                    placeholder="Enter Customer Mobile Number"
                    className="form-control w-75"
                    onBlur={e => {
                      const customerMobileNumber = e.target.value;
                      formik.setFieldValue(
                        'customerMobileNumber',
                        customerMobileNumber
                      );
                      findCustomer(customerMobileNumber, {
                        setLoading,
                        formik,
                        setCustomerDisableFields
                      });
                    }}
                    disabled={disableFields}
                  />
                </div>
                <div className="col">
                  <InputField
                    label="Customer Name"
                    name="customerName"
                    type="text"
                    placeholder="Enter Customer Name"
                    className="form-control w-75"
                    disabled={disableFields || customerDisableFields}
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <SelectField
                    label="Vehicle Wheels"
                    name="numberOfVehicleWheels"
                    options={['4', '6', '8', '10', '12', '14', '16']}
                    className="w-75"
                    disabled={disableFields}
                  />
                </div>
                <div className="col">
                  <SelectField
                    label="Vehicle Model"
                    name="vehicleModel"
                    options={
                      responseVehicleModel.length > 0
                        ? responseVehicleModel.map(model =>
                            toPascalCase(model.label)
                          )
                        : [formik.values.vehicleModel]
                    }
                    className="w-75"
                    disabled={
                      !formik.values.vehicleManufacturer ||
                      loading ||
                      disableFields
                    }
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <InputField
                    label="Vehicle Odometer Reading"
                    name="vehicleOdometerReading"
                    type="number"
                    placeholder="Enter Vehicle Odometer Reading"
                    className="form-control w-75"
                  />
                </div>
                <div className="col">
                  <InputField
                    label="Vehicle Average KM's Per Day"
                    name="vehicleAverageKmsPerDay"
                    type="number"
                    placeholder="Enter Vehicle Average KM's Per Day"
                    className="form-control w-75"
                  />
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <SelectField
                    label="Is The Vehicle With Freight?"
                    name="services.freight.selected"
                    options={['Yes', 'No']}
                    defaultOption="Choose If The Vehicle Is With Freight or Not?"
                    className="w-75"
                  />
                </div>
                {formik.values.services?.freight?.selected === 'Yes' && (
                  <div className="col-6">
                    <InputField
                      label="Freight Charge"
                      name="services.freight.freightCharge"
                      type="number"
                      placeholder="Enter Freight Price"
                      className="form-control w-75"
                    />
                  </div>
                )}
              </div>

              <div className="row mt-3">
                <div className="col">
                  <InputField
                    label="Driver Mobile Number"
                    name="driverMobileNumber"
                    type="text"
                    placeholder="Enter Driver Mobile Number"
                    className="form-control w-75"
                  />
                </div>

                <div className="col">
                  <label className="fs-14 fw-500" htmlFor="createdOn">
                    Created On
                  </label>
                  <Field
                    type="date"
                    name="createdOn"
                    className="form-control w-75"
                    value={currentDate}
                    disabled
                    id="createdOn"
                  />
                  <ErrorMessage
                    name="createdOn"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row mt-3">
                <div className="col">
                  <p className="mt-3 fw-bold pb-1 fs-14 fw-500">
                    Select the services<span className="color-star">*</span>
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="ms-2"
                      style={{ color: '#cfcfcf' }}
                    />
                  </p>
                  <p className="muted-grayish-blue fs-14 fw-400">
                    Click all the services performed
                  </p>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="wheelAlignment"
                      serviceTitle="Wheel Alignment"
                      hideQuantity={true}
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="wheelBalancing"
                      serviceTitle="Wheel Balancing"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="weightBalancing"
                      serviceTitle="Balancing Weights"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="tyreChanging"
                      serviceTitle="Tyre Changing"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="tyreRotation"
                      serviceTitle="Tyre Rotation"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="tyrePuncture"
                      serviceTitle="Tyre Puncture"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField serviceName="sims" serviceTitle="Sims" />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField
                      serviceName="nitrogen"
                      serviceTitle="Nitrogen"
                    />
                  </div>

                  <div className="col form-check mt-2">
                    <ServiceField serviceName="others" serviceTitle="Other" />
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col d-flex align-items-center">
                  <Field
                    type="checkbox"
                    name="useGstInvoice"
                    className="form-check-input me-2"
                    id="useGstInvoice"
                  />
                  <label
                    className="form-check-label mb-0 fs-14 fw-500"
                    htmlFor="useGstInvoice"
                  >
                    Use GST Invoice
                  </label>
                </div>
              </div>

              {formik.values.useGstInvoice && (
                <div className="row mt-3">
                  <div className="col">
                    <InputField
                      label="GSTIN Number"
                      name="gstinNumber"
                      type="text"
                      placeholder="Enter GSTIN Number"
                      className="form-control w-75"
                      id="gstinNumber"
                    />
                  </div>
                  <div className="col">
                    <InputField
                      label="Business Name"
                      name="businessName"
                      type="text"
                      placeholder="Enter Business Name"
                      className="form-control w-75"
                      id="businessName"
                    />
                  </div>
                </div>
              )}

              <div className="col mt-3">
                <button type="submit" className="button-indigo">
                  Generate Invoice
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default HeavyVehicleBilling;
