import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import '../../assets/scss/font-size.scss';
import '../../assets/scss/font-weight.scss';
import DataTransferService from '../../core/service/dataTransferService';
import Loader from '../../core/loader/loader';
import { updateUserProfileValidations } from '../../validations/forms';
import UserService from '../../core/service/userService';
import { toast } from 'react-toastify';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import StorageService from '../../core/service/storageService';
import CameraIcon from '../../common/cameraIcon';

const Settings = () => {
  const dataTransferService = new DataTransferService();
  const branchName = dataTransferService.getSelectedBranch();
  const organisationName = dataTransferService.getSelectedOrganization();
  const storageService = new StorageService();
  const userService = new UserService();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageError, setImageError] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [initialValues, setInitialValues] = useState({
    userProfile: null,
    userName: '',
    userEmail: '',
    userRole: ''
  });

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = () => {
    try {
      const dataTransferService = new DataTransferService();
      const userData = dataTransferService.getUserInfo();
      setInitialValues({
        userProfile: userData.userProfile || null,
        userName: (userData.userName || '').trim().replace(/\s+/g, ' '),
        userEmail: userData.userEmail || '',
        userRole: userData.userRole || ''
      });
    } catch (err) {
      console.error('Error fetching user info:', err);
      setError('Failed to fetch user information.');
    }
  };

  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 2000000) {
        setImageError('File size should be less than 2MB.');
        setSelectedImage(null);
        setFieldValue('userProfile', null);
      } else {
        setSelectedImage(URL.createObjectURL(file));
        setImageError('');
        setFieldValue('userProfile', file);
      }
    }
  };

  const isFormChanged = (initialValues, values) => {
    const { userProfile: initialProfile, ...initialRest } = initialValues;
    const { userProfile: updatedProfile, ...updatedRest } = values;
    const profileChanged =
      updatedProfile instanceof File ||
      (typeof updatedProfile === 'string' && initialProfile !== updatedProfile);
    const otherFieldsChanged =
      JSON.stringify(initialRest) !== JSON.stringify(updatedRest);

    return profileChanged || otherFieldsChanged;
  };

  const handleSubmit = values => {
    if (!isFormChanged(initialValues, values)) {
      toast.info('No changes detected.', { theme: 'colored' });
      return;
    }

    setLoading(true);
    if (typeof values.userProfile === 'object') {
      storageService
        .uploadImage(values.userProfile)
        .then(response => {
          if (response.data.responseCode === 200) {
            const userProfileUrl = response.data.responseBody;
            updateProfile(values, userProfileUrl);
          }
        })
        .catch(err => {
          setLoading(false);
          handleErrorResponse('upload-image', err.response);
        });
    } else {
      updateProfile(values, values.userProfile);
    }
  };

  const updateProfile = (values, userProfileUrl) => {
    const { userEmail, userName, userRole } = values;
    dataTransferService.setUserInfo(
      userEmail,
      userName,
      userRole,
      userProfileUrl
    );
    const user = userName.trim().replace(/\s+/g, ' ');
    userService
      .updateUserProfile(userProfileUrl, user, branchName, organisationName)
      .then(response => {
        if (response.data.responseCode === 200) {
          fetchUserInfo();
          setLoading(false);
          window.location.reload();
          toast.success(response.data.responseMessage);
        }
      })
      .catch(err => {
        setLoading(false);
        handleErrorResponse('update-profile', err.response);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={updateUserProfileValidations}
      onSubmit={handleSubmit}
    >
      {formik => (
        <div>
          <Form>
            <div className="d-flex justify-content-between">
              {loading && <Loader loading={loading} />}
              <div className="custom-container mt-36 p-4">
                <label className="fs-14 fw-500" htmlFor="userName">
                  Name <span className="color-star">*</span>
                </label>
                <Field
                  name="userName"
                  id="userName"
                  className="form-control custom-input"
                  placeholder="John"
                />
                <ErrorMessage
                  name="userName"
                  component="div"
                  className="error text-danger"
                />

                <label className="fs-14 fw-500 pt-4" htmlFor="email">
                  Email <span className="color-star">*</span>
                </label>
                <Field
                  name="userEmail"
                  id="email"
                  type="email"
                  className="form-control custom-input"
                  disabled
                />

                <label className="fs-14 fw-500 pt-4" htmlFor="userRole">
                  Role <span className="color-star">*</span>
                </label>
                <Field
                  name="userRole"
                  id="userRole"
                  className="form-control custom-input"
                  disabled
                />
              </div>

              <div className="image-upload-container mt-75">
                <div className="profile-heading mb-3">Profile Picture</div>
                <div className="image-preview">
                  {selectedImage || initialValues.userProfile ? (
                    <img
                      src={
                        selectedImage ||
                        dataTransferService.getBloburl() +
                          initialValues.userProfile
                      }
                      alt="Profile Preview"
                      className="image-round-box"
                    />
                  ) : (
                    <div className="icon-container">
                      <CameraIcon />
                    </div>
                  )}
                </div>

                <div className="pt-3">
                  <label htmlFor="upload-image" className="upload-button">
                    Upload Image
                  </label>
                  <input
                    type="file"
                    id="upload-image"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={e => handleImageChange(e, formik.setFieldValue)}
                  />
                </div>
                {imageError && (
                  <div className="error text-danger">{imageError}</div>
                )}
                <ErrorMessage
                  name="userProfile"
                  component="div"
                  className="error text-danger text-center"
                />
              </div>
            </div>

            {error && <div className="error text-danger">{error}</div>}

            <div className="d-flex justify-content-end">
              <button
                type="submit"
                className="button-indigo"
                disabled={loading}
              >
                Update
              </button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default Settings;
