import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Profile from '../../common/profile';
import { editReturnTrackerValidations } from '../../validations/forms';
import ReturnTrackerRequest from '../../core/service/returnTrackerRequest';
import ReturnTrackerService from '../../core/service/returnTrackerService';
import DataTransferService from '../../core/service/dataTransferService';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import Loader from '../../core/loader/loader';
import { Back } from '../../common/back';

const EditReturnTracker = () => {
  const [initialValues, setInitialValues] = useState({
    serialNumber: '',
    manufacturerReferenceId: '',
    tyreManufacturer: '',
    tyreModel: '',
    returnStatus: '',
    customerName: '',
    customerMobileNumber: '',
    createdOn: ''
  });

  const [currentDate, setCurrentDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dataTransferService = new DataTransferService();
  const returnTrackerService = new ReturnTrackerService();
  const navigate = useNavigate();
  const { serialNumber } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const dataTransferService = new DataTransferService();
      try {
        const branchName = dataTransferService.getSelectedBranch();
        const organisationName = dataTransferService.getSelectedOrganization();
        const returnTrackerRequest = new ReturnTrackerRequest();
        const response = await returnTrackerRequest.fetchReturnTrackerDetails(
          serialNumber,
          organisationName,
          branchName
        );

        const returnRequest = response.data.responseBody;
        if (returnRequest) {
          setLoading(false);
          setInitialValues({
            serialNumber: returnRequest.serialNumber,
            manufacturerReferenceId: returnRequest.manufacturerId,
            tyreManufacturer: returnRequest.tyreManufacturer,
            tyreModel: returnRequest.tyreModel,
            returnStatus: returnRequest.returnStatus,
            customerName: returnRequest.customerName,
            customerMobileNumber: returnRequest.customerMobileNumber
          });
        }
      } catch (error) {
        setLoading(false);
        if (error.response?.data?.responseMessage) {
          setError(error.response.data.responseMessage);
        } else {
          handleErrorResponse('fetchData', error.response);
        }
      }
    };

    fetchData();
  }, [serialNumber]);

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setCurrentDate(formattedDate);
  }, []);

  const handleSubmit = async values => {
    if (values.returnStatus !== initialValues.returnStatus) {
      const formattedData = {
        returnStatus: values.returnStatus
      };
      submitForm(formattedData);
    } else {
      toast.info('No changes in return status.', { theme: 'colored' });
    }
  };

  const submitForm = formattedData => {
    setLoading(true);
    const branchName = dataTransferService.getSelectedBranch();
    const organisationName = dataTransferService.getSelectedOrganization();
    returnTrackerService
      .updateReturnTracker(
        organisationName,
        branchName,
        serialNumber,
        formattedData.returnStatus
      )
      .then(response => {
        if (response.data.responseCode === 200) {
          setLoading(false);
          navigate('/return-tracker');
          toast.success(response.data.responseMessage, { theme: 'colored' });
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('submitForm', error.response);
      });
  };

  if (error) {
    return (
      <div className="vh-100 position-relative d-flex justify-content-center align-items-center">
        <div className="position-absolute top-0 end-0 mt-3 p-4">
          <Profile />
        </div>
        <p className="text-danger">{error}</p>
      </div>
    );
  }

  return (
    <div className="p-4">
      {loading && <Loader loading={loading} />}

      <div className="d-flex justify-content-end mt-15">
        <Profile />
      </div>

      <div className="container new-return-tracker-container p-4 mt-5">
        <div className="d-flex align-items-center">
          <Back />
          <p className="ps-2 fs-22 fw-600"> Edit Tracker Details</p>
        </div>
        <div>
          <hr className="pt-3 hr-color" />
        </div>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={editReturnTrackerValidations}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <div className="row pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-500" htmlFor="serialNumber">
                    Serial Number<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="serialNumber"
                    placeholder="Enter Tyre Serial Number"
                    className="form-control narrow-input"
                    disabled
                  />
                  <ErrorMessage
                    name="serialNumber"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col">
                  <label
                    className="label-text fw-500"
                    htmlFor="manufacturerReferenceId"
                  >
                    Manufacturer Reference Id
                    <span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="manufacturerReferenceId"
                    placeholder="Enter Reference Tracker Id"
                    className="form-control narrow-input"
                    disabled
                  />
                  <ErrorMessage
                    name="manufacturerReferenceId"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <label
                    className="label-text fw-500"
                    htmlFor="tyreManufacturer"
                  >
                    Tyre Manufacturer<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="tyreManufacturer"
                    placeholder="Enter Tyre Manufacturer"
                    className="form-control narrow-input"
                    disabled
                  />
                </div>

                <div className="col">
                  <label className="label-text fw-500" htmlFor="tyreModel">
                    Tyre Model<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="tyreModel"
                    placeholder="Enter Tyre Model"
                    className="form-control narrow-input"
                    disabled
                  />
                </div>
              </div>

              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-500" htmlFor="customerName">
                    Customer Name<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="customerName"
                    placeholder="Enter Customer Name"
                    className="form-control narrow-input"
                    disabled
                  />
                  <ErrorMessage
                    name="customerName"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="col">
                  <label
                    className="label-text fw-500"
                    htmlFor="customerMobileNumber"
                  >
                    Customer Mobile Number<span className="color-star">*</span>
                  </label>
                  <Field
                    type="text"
                    name="customerMobileNumber"
                    placeholder="Enter Customer Mobile Number"
                    className="form-control narrow-input"
                    disabled
                  />
                  <ErrorMessage
                    name="customerMobileNumber"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>

              <div className="row pt-3 pe-4 ps-3">
                <div className="col">
                  <label className="label-text fw-500" htmlFor="createdOn">
                    Created On
                  </label>
                  <Field
                    type="date"
                    name="createdOn"
                    className="form-control narrow-input"
                    value={currentDate}
                    disabled
                  />
                </div>
                <div className="col">
                  <label className="label-text fw-500" htmlFor="returnStatus">
                    Return Status<span className="color-star">*</span>
                  </label>
                  <Field
                    as="select"
                    name="returnStatus"
                    className="form-select narrow-input"
                  >
                    <option value="" disabled>
                      Select Status
                    </option>
                    <option value="IN_PROCESS">IN_PROCESS</option>
                    <option value="PASSED_QC">PASSED_QC</option>
                    <option value="FAILED_QC">FAILED_QC</option>
                  </Field>
                  <ErrorMessage
                    name="returnStatus"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </div>
              <div className="mt-4 mb-3 d-flex justify-content-end">
                <button type="submit" className="button-indigo">
                  Update
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditReturnTracker;
