import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { organisationValidations } from '../../validations/forms';
import '../../assets/scss/organisation.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import StorageService from '../../core/service/storageService';
import Loader from '../../core/loader/loader';
import DataTransferService from '../../core/service/dataTransferService';
import { useNavigate } from 'react-router-dom';
import { InputField } from '../../validations/inputFields';
import CameraIcon from '../../common/cameraIcon';

const Organisation = () => {
  const dataTransferService = new DataTransferService();
  const storageService = new StorageService();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageError, setImageError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleImageChange = (e, setFieldValue) => {
    const file = e.currentTarget.files[0];
    if (file) {
      if (file.size > 2000000) {
        setImageError('File size should be less than 2MB');
        setSelectedImage(null);
        setFieldValue('organisationProfile', null);
      } else {
        setSelectedImage(URL.createObjectURL(file));
        setImageError('');
        setFieldValue('organisationProfile', file);
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault();
      event.returnValue = '';
    };

    const handlePopState = event => {
      const confirmLeave = window.confirm(
        'Are you sure you want to leave this page?'
      );
      if (!confirmLeave) {
        window.history.pushState(null, '', window.location.href);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const renderImagePreview = () => {
    if (loading) {
      return <Loader loading={loading} />;
    }
    if (selectedImage) {
      return (
        <img
          src={selectedImage}
          alt="Uploaded Preview"
          className="image-round-box"
        />
      );
    }
    return (
      <div className="icon-container">
        <CameraIcon />
      </div>
    );
  };

  return (
    <Formik
      initialValues={{
        organisationName: '',
        numLocations: '',
        organisationProfile: null
      }}
      validationSchema={organisationValidations}
      onSubmit={values => {
        setLoading(true);
        storageService
          .uploadImage(values.organisationProfile)
          .then(response => {
            if (response.data.responseCode === 200) {
              const { organisationName } = values;
              const { numLocations } = values;
              const imageName = response.data.responseBody;
              const organisationDTO = {
                organisationName: organisationName.trim().replace(/\s+/g, ' '),
                organisationProfile: imageName,
                numLocations,
                firstTime: true,
                branchDTOList: []
              };
              dataTransferService.setOrganisationDTO(
                JSON.stringify(organisationDTO)
              );
              setLoading(false);
              navigate('/create-branches');
            }
          })
          .catch(error => {
            setLoading(false);
            handleErrorResponse('upload-image', error.response);
          });
      }}
    >
      {({ setFieldValue }) => (
        <div className="full-height">
          <div className="organization-container">
            <p className="hi-user-text ps-3">
              Hi {dataTransferService.getUserName()}
            </p>
            <div className="profile-whole-container p-3">
              <p className="profile-top-text">
                Let's get started on creating your organisations.
              </p>

              <div className="image-upload-container">
                <div className="image-preview">{renderImagePreview()}</div>
                <div className="upload-button-container pt-1">
                  <label
                    htmlFor="upload-image"
                    className="upload-button-styling"
                  >
                    Upload Image
                  </label>
                  <input
                    type="file"
                    id="upload-image"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={e => handleImageChange(e, setFieldValue)}
                  />
                </div>
                {imageError && (
                  <div className="error text-danger ">{imageError}</div>
                )}
                <ErrorMessage name="organisationProfile">
                  {msg => (
                    <div className="error text-danger text-center">{msg}</div>
                  )}
                </ErrorMessage>
              </div>

              <div className="organization-form">
                <Form>
                  <InputField
                    label="Enter your business name"
                    name="organisationName"
                    type="text"
                    placeholder="Sri Jayalakshmi Trading Co"
                    className="org-placeholder-text"
                  />

                  <div className="mb-3 no-of-locations-container">
                    <label
                      className="number-of-locations-text fw-500"
                      htmlFor="numLocations"
                    >
                      Select number of business locations
                      <span className="color-star">*</span>
                    </label>
                    <Field
                      as="select"
                      name="numLocations"
                      id="numLocations"
                      className="form-select org-placeholder-text"
                    >
                      <option value="" label="Select..." disabled />
                      <option value="1" label="1" />
                      <option value="2" label="2" />
                      <option value="3" label="3" />
                      <option value="4" label="4" />
                      <option value="5" label="5" />
                      <option value="6" label="6" />
                      <option value="7" label="7" />
                      <option value="8" label="8" />
                    </Field>
                    <ErrorMessage name="numLocations">
                      {msg => <div className="text-danger">{msg}</div>}
                    </ErrorMessage>
                  </div>

                  <div className="d-flex justify-content-center">
                    <button
                      type="submit"
                      className="p-2 button-styling"
                      disabled={loading}
                    >
                      Next
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default Organisation;
