export const toPascalCase = str => {
  if (!str) return '';
  return str.toLowerCase().replace(/(^\w|\s\w)/g, match => match.toUpperCase());
};

export const formatLocalDate = date => {
  if (!date) return '';
  const validDate = new Date(date);
  if (isNaN(validDate.getTime())) return '';
  const year = validDate.getFullYear();
  const month = (validDate.getMonth() + 1).toString().padStart(2, '0');
  const day = validDate.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};
