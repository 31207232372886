import React, { useState, useEffect, useCallback } from 'react';
import { Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import UserService from '../../core/service/userService';
import DataTransferService from '../../core/service/dataTransferService';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import Loader from '../../core/loader/loader';
import Calendar from '../../common/calender';
import { formatLocalDate } from '../../common/shared';

const AllBranchesReport = () => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [dropdownLabel, setDropdownLabel] = useState('Calender');
  const [loading, setLoading] = useState(false);
  const [branchesData, setBranchesData] = useState([]);
  const [billingData, setBillingData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const dataTransferService = new DataTransferService();
  const userRole = dataTransferService.getUserInfo().userRole;

  const downloadCSV = () => {
    const csvHeaders = [
      'Branch Name',
      'Cash Total',
      'Credit Total',
      'UPI Total',
      'Credit Card Total',
      'Total Amount'
    ];
    const csvRows = branchesData.map(branch => [
      branch.branchName,
      branch.cashTotal,
      branch.creditTotal,
      branch.upiTotal,
      branch.creditCardTotal,
      branch.totalAmount
    ]);

    const csvContent = [csvHeaders, ...csvRows]
      .map(row => row.join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Branches_Report.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const fetchData = useCallback((startDate, endDate) => {
    setLoading(true);
    const dataTransferService = new DataTransferService();
    const organisationName = dataTransferService.getSelectedOrganization();
    const formattedStartDate = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split('T')[0];

    const formattedEndDate = new Date(
      endDate.getTime() - endDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split('T')[0];
    const userService = new UserService();
    userService
      .allBranchesReport(organisationName, formattedStartDate, formattedEndDate)
      .then(response => {
        if (response.data.responseCode === 200) {
          setBranchesData(response.data.responseBody.branchesData);
          setBillingData(response.data.responseBody);
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('searchServices', error.response);
      });
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const formattedStart = startDate.toLocaleDateString();
      const formattedEnd = endDate.toLocaleDateString();
      if (startDate.toDateString() === endDate.toDateString()) {
        setDropdownLabel(`${formattedStart}`);
      } else {
        setDropdownLabel(`${formattedStart} - ${formattedEnd}`);
      }

      fetchData(startDate, endDate);
    }
  }, [startDate, endDate, fetchData]);

  const handleDateChange = update => {
    const [start, end] = update;
    setStartDate(start);
    setEndDate(end);

    if (start && end) {
      const formattedStart = start.toLocaleDateString();
      const formattedEnd = end.toLocaleDateString();
      setDropdownLabel(`${formattedStart} - ${formattedEnd}`);
      setShowCalendar(false);
    }
  };

  const handleSortClick = () => {
    setShowCalendar(false);
  };

  return (
    <div className="invoice-whole-container mt-5">
      {loading && <Loader loading={loading} />}

      <div className="billing-container">
        <div className="box-cash-transactions">
          <div>
            <h5 className="text-color">Cash Transactions</h5>
          </div>
          <hr className="box-cash-transactions-gap" />
          <h3 className="fs-3 fw-bold rupee-style">
            ₹ {billingData?.organisationCashTotal || '0'}
          </h3>
        </div>

        <div className="box-cash-transactions">
          <div>
            <h5 className="text-color">Credit Transactions</h5>
          </div>
          <hr className="box-cash-transactions-gap" />
          <h3 className="fs-3 fw-bold rupee-style">
            ₹ {billingData?.organisationCreditTotal || '0'}
          </h3>
        </div>
        <div className="box-cash-transactions">
          <div>
            <h5 className="text-color">UPI Transactions</h5>
          </div>
          <hr className="box-cash-transactions-gap" />
          <h3 className="fs-3 fw-bold rupee-style">
            ₹ {billingData?.organisationUpiTotal || '0'}
          </h3>
        </div>
      </div>

      <div className="billing-container pt-3 d-flex justify-content-evenly">
        <div className="box-cash-transactions">
          <div>
            <h5 className="text-color">Credit Card Transactions</h5>
          </div>
          <hr className="box-cash-transactions-gap" />
          <h3 className="fs-3 fw-bold rupee-style">
            ₹ {billingData?.organisationCreditCardTotal || '0'}
          </h3>
        </div>
        <div className="box-cash-transactions">
          <div>
            <h5 className="text-color">Total</h5>
          </div>
          <hr className="box-cash-transactions-gap" />
          <h3 className="fs-3 fw-bold rupee-style">
            ₹ {billingData?.organisationTotalAmount || '0'}
          </h3>
        </div>
        <div className="box-cash-transactions">
          <div>
            <h5 className="text-color">Vehicles Serviced</h5>
          </div>
          <hr className="box-cash-transactions-gap" />
          <h3 className="fs-3 fw-bold rupee-style">
            {billingData?.totalVehiclesServiced || '0'}
          </h3>
        </div>
      </div>

      <div className="second-container">
        <div className="box-summary-report">
          <div className="summary report">
            <p className="fs-22 fw-600">Summary Report</p>
          </div>

          <div className="d-flex justify-content-end align-items-center p-3">
            <div className="dropdown-select p-3 d-flex align-items-center">
              <button
                onClick={handleSortClick}
                className="fs-14 fw-500 grayish-blue pe-2 text-nowrap btn-no-style"
              >
                Sort by:
              </button>
              <Calendar
                startDate={formatLocalDate(startDate)}
                endDate={formatLocalDate(endDate)}
                onDateChange={handleDateChange}
                dropdownLabel={dropdownLabel}
                showCalendar={showCalendar}
                setShowCalendar={setShowCalendar}
              />
            </div>
          </div>

          <div className="pt-3">
            <Table>
              <thead className="custom-table th">
                <tr>
                  <th>Branch Name</th>
                  <th>Cash Total</th>
                  <th>Credit Total</th>
                  <th>UPI Total</th>
                  <th>Credit Card Total</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody className="custom-table td">
                {branchesData.length > 0 &&
                  branchesData.map(branch => (
                    <tr key={branch.branchName}>
                      <td className="fs-6">{branch.branchName}</td>
                      <td className="fs-5">
                        ₹ {branch.cashTotal.toLocaleString()}
                      </td>
                      <td className="fs-5">
                        ₹ {branch.creditTotal.toLocaleString()}
                      </td>
                      <td className="fs-5">
                        ₹ {branch.upiTotal.toLocaleString()}
                      </td>
                      <td className="fs-5">
                        ₹ {branch.creditCardTotal.toLocaleString()}
                      </td>
                      <td className="fs-5">
                        ₹ {branch.totalAmount.toLocaleString()}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          <div className="mt-5 d-flex justify-content-end">
            {['ADMIN', 'MANAGER'].includes(userRole) && (
              <button className="download-report-button" onClick={downloadCSV}>
                <FontAwesomeIcon icon={faFileLines} /> Download Report
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllBranchesReport;
