import { React, useState } from 'react';
import { Formik, Form } from 'formik';
import { InputField } from '../../validations/inputFields';
import { addTyreValidations } from '../../validations/forms';
import Loader from '../../core/loader/loader';
import UserService from '../../core/service/userService';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import { toast } from 'react-toastify';

const AddTyres = () => {
  const [loading, setLoading] = useState(false);
  const tyreInitialValues = {
    tyreManufacturer: '',
    tyreModel: ''
  };

  const handleTyreSubmit = (values, { resetForm }) => {
    setLoading(true);
    const { tyreManufacturer, tyreModel } = values;
    const manufacturer = tyreManufacturer.trim().replace(/\s+/g, ' ');
    const model = tyreModel.trim().replace(/\s+/g, ' ');

    const userService = new UserService();
    userService
      .addTyreInfo(manufacturer, model)
      .then(response => {
        if (response.data.responseCode === 201) {
          setLoading(false);
          toast.success(response.data.responseMessage);
        }
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('handleTyreSubmit', error.response);
      })
      .finally(() => {
        resetForm();
      });
  };

  return (
    <>
      {loading && <Loader loading={loading} />}
      <div className="p-3 mt-3">
        <h1 className="vehicles-heading">Add Tyre Details</h1>
      </div>
      <div className="p-3">
        <h2 className="sub-heading">Tyre Details</h2>
      </div>

      <Formik
        initialValues={tyreInitialValues}
        enableReinitialize
        validationSchema={addTyreValidations}
        onSubmit={(values, { resetForm }) =>
          handleTyreSubmit(values, { resetForm })
        }
      >
        {formik => (
          <Form>
            <div className="row p-3">
              <div className="col-md-6">
                <InputField
                  label="Tyre Manufacturer"
                  name="tyreManufacturer"
                  type="text"
                  placeholder="Enter Tyre Manufacturer"
                  className="form-control vehicle-input"
                />
              </div>
              <div className="col-md-6">
                <InputField
                  label="Tyre Model"
                  name="tyreModel"
                  type="text"
                  placeholder="Enter Tyre Model"
                  className="form-control vehicle-input"
                />
              </div>
            </div>
            <div className="d-flex justify-content-end m-4">
              <button className="vehicle-button">Add Tyre</button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default AddTyres;
