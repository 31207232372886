import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

export const InputField = ({
  label,
  name,
  type,
  placeholder,
  className,
  ...props
}) => (
  <>
    <label className="fs-14 fw-500" htmlFor={name}>
      {label} <span className="color-star">*</span>
    </label>

    <Field
      type={type}
      name={name}
      placeholder={placeholder}
      className={className}
      id={name}
      onKeyDown={e => {
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          e.preventDefault();
        }
      }}
      onWheel={e => e.target.blur()}
      {...props}
    />

    <ErrorMessage name={name} component="div" className="text-danger" />
  </>
);

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string
};

export const ServiceField = ({
  serviceName,
  serviceTitle,
  className,
  hideQuantity = false,
  ...props
}) => (
  <>
    <Field
      type="checkbox"
      className="form-check-input me-1"
      name={`services.${serviceName}.selected`}
      id={`checkbox-${serviceName}`}
    />

    <label
      className="form-check-label fs-14 fw-500"
      htmlFor={`checkbox-${serviceName}`}
    >
      {serviceTitle}
    </label>

    <div className={`d-flex input-size gap-3 ${hideQuantity ? 'w-50' : ''}`}>
      {!hideQuantity && (
        <div className="w-50">
          <Field
            type={serviceName === 'others' ? 'text' : 'number'}
            name={`services.${serviceName}.${serviceName}Quantity`}
            placeholder={serviceName === 'others' ? 'Description' : 'Quantity'}
            className={`form-control mt-1 ${className || ''}`}
            id={`quantity-${serviceName}`}
            onKeyDown={e => {
              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                e.preventDefault();
              }
            }}
            onWheel={e => e.target.blur()}
            {...props}
          />
          <ErrorMessage
            name={`services.${serviceName}.${serviceName}Quantity`}
            component="div"
            render={msg => (
              <div className="text-danger mt-1">
                {serviceName === 'others'
                  ? msg.replace('Quantity', 'Description')
                  : msg}
              </div>
            )}
          />
        </div>
      )}

      <div className="w-50">
        <Field
          type="number"
          name={`services.${serviceName}.${serviceName}Price`}
          placeholder="Price"
          className={`form-control mt-1 ${className || ''}`}
          id={`price-${serviceName}`}
          onKeyDown={e => {
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
              e.preventDefault();
            }
          }}
          onWheel={e => e.target.blur()}
          {...props}
          {...props}
        />
        <ErrorMessage
          name={`services.${serviceName}.${serviceName}Price`}
          component="div"
          className="text-danger mt-1"
        />
      </div>
    </div>
  </>
);

ServiceField.propTypes = {
  serviceName: PropTypes.string.isRequired,
  serviceTitle: PropTypes.string.isRequired,
  className: PropTypes.string,
  hideQuantity: PropTypes.bool
};

export const SelectField = ({
  label,
  name,
  options,
  defaultOption,
  className,
  ...props
}) => {
  return (
    <>
      <label className="fs-14 fw-500" htmlFor={name}>
        {label} <span className="color-star">*</span>
      </label>

      <Field
        as="select"
        name={name}
        className={`form-select ${className || ''}`}
        id={name}
        {...props}
      >
        <option value="">
          {defaultOption || `Select ${label.toLowerCase()}`}
        </option>
        {options.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Field>

      <ErrorMessage name={name} component="div" className="text-danger" />
    </>
  );
};

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    ])
  ).isRequired,
  defaultOption: PropTypes.string,
  className: PropTypes.string
};
