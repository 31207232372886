import { Appconfig } from '../config/appConfig';
import axiosInstance from '../tokenInterceptor/axiosInstance';

export class ServiceRequest {
  constructor() {
    this.updateAddressSubject = [];
    this.Api = Appconfig.getAPIURI() + 'service-request';
  }

  fetchServices(order, page, size, branchName, organisationName) {
    return axiosInstance.get(`${this.Api}/service`, {
      params: { order, page, size, branchName, organisationName }
    });
  }

  searchServices(searchTerm, page, size, branchName, organisationName) {
    return axiosInstance.get(`${this.Api}/search`, {
      params: { searchTerm, page, size, branchName, organisationName }
    });
  }

  alertByVehicleNumber(branchName, organisationName, vehicleNumber) {
    return axiosInstance.get(`${this.Api}/alert`, {
      params: { branchName, organisationName, vehicleNumber }
    });
  }

  serviceHistory(branchName, organisationName, vehicleNumber, page, size) {
    return axiosInstance.get(`${this.Api}/history`, {
      params: { branchName, organisationName, vehicleNumber, page, size }
    });
  }

  paymentStatus(page, size, organisationName, branchName, startDate, endDate) {
    return axiosInstance.get(`${this.Api}/payment-status-by-date`, {
      params: { page, size, organisationName, branchName, startDate, endDate }
    });
  }

  fetchInvoice(invoiceSerialNumber, branchName, organisationName) {
    return axiosInstance.get(`${this.Api}`, {
      params: { invoiceSerialNumber, branchName, organisationName }
    });
  }

  fetchAlert(vehicleNumber, branchName, organisationName) {
    return axiosInstance.get(`${this.Api}/alert`, {
      params: { vehicleNumber, branchName, organisationName }
    });
  }

  searchCreditDetails(searchTerm, page, size, branchName, organisationName) {
    return axiosInstance.get(`${this.Api}/search-credit`, {
      params: { searchTerm, page, size, branchName, organisationName }
    });
  }

  fetchVehicleBilling(vehicleType, page, size, organisationName, branchName) {
    return axiosInstance.get(`${this.Api}/billing`, {
      params: { vehicleType, page, size, organisationName, branchName }
    });
  }

  searchBilling(vehicleNumber, page, size, organisationName, branchName) {
    return axiosInstance.get(`${this.Api}/search-billing`, {
      params: { vehicleNumber, page, size, organisationName, branchName }
    });
  }

  vehicleModels(vehicleType, vehicleManufacturer) {
    return axiosInstance.get(`${this.Api}/vehicle-models`, {
      params: { vehicleType, vehicleManufacturer }
    });
  }

  vehicleManufacturer(vehicleType) {
    return axiosInstance.get(`${this.Api}/vehicle-manufacturers`, {
      params: { vehicleType }
    });
  }

  fetchServicesWithDate(
    organisationName,
    branchName,
    page,
    size,
    startDate,
    endDate
  ) {
    return axiosInstance.get(`${this.Api}/service-and-transactions`, {
      params: { organisationName, branchName, page, size, startDate, endDate }
    });
  }

  amountTotal(organisationName, branchName, startDate, endDate) {
    return axiosInstance.get(`${this.Api}/total-counts`, {
      params: {
        organisationName,
        branchName,
        startDate,
        endDate
      }
    });
  }
}

export default ServiceRequest;
