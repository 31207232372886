import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import DataTransferService from '../../core/service/dataTransferService';
import { useFormik } from 'formik';
import { createBranchesValidation } from '../../validations/forms';
import Flex from '../../common/flex';
import DefaultProfile from '../../assets/images/defaultProfile.png';

const CreateBranches = () => {
  const [organisationName, setOrganisationName] = useState('');
  const [numLocations, setNumLocations] = useState(0);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const dataTransferService = new DataTransferService();
  const [isFormVisible, setFormVisible] = useState(false);
  const [showLocationInput, setShowLocationInput] = useState(false);
  const [branchInput, setBranchInput] = useState(false);
  const navigate = useNavigate();
  const userProfileUrl = dataTransferService.getUserProfile()
    ? `${dataTransferService.getBloburl()}${dataTransferService.getUserProfile()}`
    : DefaultProfile;

  const goBack = () => {
    dataTransferService.removeBranchDTO();
    window.history.back();
  };

  useEffect(() => {
    const dataTransferService = new DataTransferService();
    const handlePopState = () => {
      dataTransferService.removeBranchDTO();
      window.history.back();
    };

    const handleBeforeUnload = () => {
      dataTransferService.removeBranchDTO();
      window.history.back();
    };

    window.history.pushState(null, '', window.location.href);

    window.addEventListener('popstate', handlePopState);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const dataTransferService = new DataTransferService();
    const organisationDTO = dataTransferService.getOrganisationDTO();
    if (organisationDTO) {
      setOrganisationName(organisationDTO.organisationName || '');
      setNumLocations(organisationDTO.numLocations || 0);
      if (organisationDTO.numLocations && organisationDTO.firstTime === false) {
        setShowLocationInput(true);
      } else {
        setFormVisible(false);
      }
      return;
    }

    const branchDTO = dataTransferService.getBranchDTO();
    if (branchDTO) {
      setOrganisationName(branchDTO.organisationName || '');
      setNumLocations(branchDTO.numLocations || 0);
      if (branchDTO.numLocations && branchDTO.firstTime === false) {
        setShowLocationInput(true);
      } else {
        setFormVisible(false);
      }
    }
  }, []);

  const handleFormOpen = () => {
    setFormVisible(true);
  };

  const handleFormClose = () => {
    setFormVisible(false);
    setCurrentFormIndex(0);
  };

  const handleNextForm = () => {
    setCurrentFormIndex(prevIndex => prevIndex + 1);
  };

  const formik = useFormik({
    initialValues: {
      branchName: '',
      branchAddress: '',
      branchMobileNumber: '',
      storeAddressLink: ''
    },
    validationSchema: createBranchesValidation,
    onSubmit: (values, { resetForm, setFieldError }) => {
      const branchListString = localStorage.getItem('listOfBranches') || '';
      const branchList = branchListString
        .split(',')
        .map(branch => branch.trim().toLowerCase());

      if (branchList.includes(values.branchName.toLowerCase())) {
        setFieldError('branchName', 'Branch name already exists');
        return;
      }

      const newBranch = {
        branchName: values.branchName.trim().replace(/\s+/g, ' '),
        branchAddress: values.branchAddress.trim().replace(/\s+/g, ' '),
        branchMobileNumber: values.branchMobileNumber,
        storeAddressLink: values.storeAddressLink
      };

      if (branchInput) {
        const branchDTO = dataTransferService.getBranchDTO() || {
          branchDTOList: []
        };
        branchDTO.branchDTOList.push(newBranch);
        dataTransferService.setBranchDTO(JSON.stringify(branchDTO));

        if (currentFormIndex + 1 >= numLocations) {
          branchDTO.firstTime = false;
          dataTransferService.setBranchDTO(JSON.stringify(branchDTO));
          handleFormClose();
          navigate('/view-branches');
        } else {
          resetForm();
          handleNextForm();
        }
      } else {
        const organisationDTO = dataTransferService.getOrganisationDTO() || {
          branchDTOList: []
        };
        organisationDTO.branchDTOList.push(newBranch);
        dataTransferService.setOrganisationDTO(JSON.stringify(organisationDTO));
        if (currentFormIndex + 1 >= numLocations) {
          organisationDTO.firstTime = false;
          dataTransferService.setOrganisationDTO(
            JSON.stringify(organisationDTO)
          );
          handleFormClose();
          navigate('/view-branches');
        } else {
          resetForm();
          handleNextForm();
        }
      }
    }
  });

  const handleNumLocationsSubmit = event => {
    event.preventDefault();
    setShowLocationInput(false);
    setBranchInput(true);
    setFormVisible(true);
  };

  useEffect(() => {
    const handleBeforeUnload = event => {
      event.preventDefault();
      event.returnValue = '';
    };

    const handlePopState = () => {
      const confirmLeave = window.confirm(
        'Are you sure you want to leave this page?'
      );
      if (!confirmLeave) {
        window.history.pushState(null, '', window.location.href);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    if (isFormVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isFormVisible]);

  return (
    <div className="p-4">
      <div
        className={`d-flex align-items-center justify-content-center full-height ${
          isFormVisible ? 'blurred' : ''
        }`}
      >
        <div className="view-branches-container p-4">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={goBack}
              className="pointer"
            />
            <p className="fs-22 fw-600 ps-3 w-100">
              Hi {dataTransferService.getUserName()}
            </p>
            <img
              src={userProfileUrl}
              alt="user-profile-icon"
              className="rounded-circle"
              width={42}
              height={42}
              style={{
                objectFit: 'cover'
              }}
            />
          </div>
          <hr />

          {showLocationInput && (
            <form onSubmit={handleNumLocationsSubmit}>
              <div className="mt-5 d-flex align-items-center justify-content-center">
                <div className="create-branches-container">
                  <p>
                    You already have {numLocations} locations. How many more
                    would you like to add?
                  </p>
                  <input
                    type="number"
                    min="1"
                    onChange={e => setNumLocations(parseInt(e.target.value))}
                    className="form-control"
                    placeholder="Enter number of locations"
                    required
                  />
                  <button type="submit" className="btn btn-primary mt-3">
                    Proceed
                  </button>
                </div>
              </div>
            </form>
          )}

          {!showLocationInput && (
            <div className="mt-5 d-flex align-items-center justify-content-center">
              <div className="create-branches-container">
                <div className="d-flex align-items-center justify-content-center ">
                  <p className="fs-4 fw-600">
                    {organisationName || 'Organisation Name'}
                  </p>
                  <button
                    type="button"
                    className="btn btn-outline custom-btn-outline ms-4"
                    onClick={handleFormOpen}
                  >
                    + Add location
                  </button>
                </div>
                <div className="pt-40 w-100 ">
                  <p className="pt-3 fs-6 fw-600">Your locations</p>
                </div>
                <div className="pt-4 w-100">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by location, name"
                      disabled
                    />
                    <span className="input-group-text bg-white border-left-0">
                      <FontAwesomeIcon icon={faSearch} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {isFormVisible && (
        <div className="modal-overlay">
          <Flex className="full-height">
            <div className="loc-form-container d-flex flex-column">
              <h2 className="fw-600 fs-22 mt-4 mb-70">
                Add new location ({currentFormIndex + 1} / {numLocations})
              </h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="loc-input-container">
                  <p className="fs-14 fw-500 mt-5">
                    Enter your Business Name
                    <span className="text-danger">*</span>
                  </p>
                  <input
                    type="text"
                    className="loc-input-field fw-bold"
                    name="businessName"
                    placeholder={organisationName || 'Organisation Name'}
                    disabled
                  />
                  <p className="fs-14 fw-500">
                    Branch Name<span className="text-danger">*</span>
                  </p>
                  <input
                    type="text"
                    className={`loc-input-field ${
                      formik.touched.branchName && formik.errors.branchName
                        ? 'error'
                        : ''
                    }`}
                    name="branchName"
                    placeholder="Enter Your Branch Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.branchName}
                  />
                  {formik.touched.branchName && formik.errors.branchName && (
                    <div className="text-danger">
                      {formik.errors.branchName}
                    </div>
                  )}

                  <p className="fs-14 fw-500">
                    Address<span className="text-danger">*</span>
                  </p>
                  <input
                    type="text"
                    className={`loc-input-field ${
                      formik.touched.branchAddress &&
                      formik.errors.branchAddress
                        ? 'error'
                        : ''
                    }`}
                    name="branchAddress"
                    placeholder="Enter Your Branch Address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.branchAddress}
                  />
                  {formik.touched.branchAddress &&
                    formik.errors.branchAddress && (
                      <div className="text-danger">
                        {formik.errors.branchAddress}
                      </div>
                    )}

                  <p className="fs-14 fw-500">
                    Mobile Number<span className="text-danger">*</span>
                  </p>
                  <input
                    type="text"
                    className={`loc-input-field ${
                      formik.touched.branchMobileNumber &&
                      formik.errors.branchMobileNumber
                        ? 'error'
                        : ''
                    }`}
                    name="branchMobileNumber"
                    placeholder="Enter Mobile Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.branchMobileNumber}
                  />
                  {formik.touched.branchMobileNumber &&
                    formik.errors.branchMobileNumber && (
                      <div className="text-danger">
                        {formik.errors.branchMobileNumber}
                      </div>
                    )}
                  <p className="fs-14 fw-500">
                    Store Link<span className="text-danger">*</span>
                  </p>
                  <input
                    type="text"
                    className={`loc-input-field placeholder-text ${
                      formik.touched.storeAddressLink &&
                      formik.errors.storeAddressLink
                        ? 'error'
                        : ''
                    }`}
                    name="storeAddressLink"
                    placeholder="Enter Store Link"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.storeAddressLink}
                  />
                  {formik.touched.storeAddressLink &&
                    formik.errors.storeAddressLink && (
                      <div className="text-danger">
                        {formik.errors.storeAddressLink}
                      </div>
                    )}
                  <div className="d-flex justify-content-end mt-4">
                    <button
                      type="button"
                      className="btn btn-secondary me-2"
                      onClick={handleFormClose}
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Flex>
        </div>
      )}
    </div>
  );
};

export default CreateBranches;
