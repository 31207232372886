import { Appconfig } from '../config/appConfig';
import axiosInstance from '../tokenInterceptor/axiosInstance';

export class InvoiceService {
  constructor() {
    this.updateAddressSubject = [];
    this.Api = Appconfig.getAPIURI() + 'invoice';
  }

  generateheavyWheelerInvoice(branchName, organisationName, body) {
    return axiosInstance.post(this.Api, body, {
      params: { branchName, organisationName }
    });
  }

  updatePaymentType(
    invoiceSerialNumber,
    paymentType,
    branchName,
    organisationName,
    body
  ) {
    return axiosInstance.put(this.Api, body, {
      params: {
        invoiceSerialNumber,
        paymentType,
        branchName,
        organisationName
      }
    });
  }

  updateCreditstatus(
    creditStatus,
    invoiceSerialNumber,
    branchName,
    organisationName
  ) {
    return axiosInstance.put(`${this.Api}/credit-status`, null, {
      params: {
        creditStatus,
        invoiceSerialNumber,
        branchName,
        organisationName
      }
    });
  }

  generateCarInvoice(branchName, organisationName, body) {
    return axiosInstance.post(this.Api, body, {
      params: { branchName, organisationName }
    });
  }
}
export default InvoiceService;
