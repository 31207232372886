import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DisabledDropdownComponent } from '../../common/dropDown';
import DataTransferService from '../../core/service/dataTransferService';
import DefaultProfile from '../../assets/images/defaultProfile.png';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import UserService from '../../core/service/userService';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../core/loader/loader';
import ConfirmDeleteModal from '../../common/confirmDeleteModal';

const ManageUsers = () => {
  const dataTransferService = new DataTransferService();
  const organisationName = dataTransferService.getSelectedOrganization();
  const sameUser = dataTransferService.getUserMobileNumber();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  const fetchUsers = useCallback(organisationName => {
    setLoading(true);
    const userService = new UserService();
    userService
      .fetchUsers(organisationName)
      .then(response => {
        if (response.data.responseCode === 200) {
          setUsers(response.data.responseBody);
        }
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('admin-fetch-users', error.response);
      });
  }, []);

  useEffect(() => {
    fetchUsers(organisationName);
  }, [organisationName, fetchUsers]);

  const handleProceed = () => {
    navigate('/invite-form');
  };

  const handleDeleteClick = user => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const handleEditClick = userEmail => {
    navigate(`/edit-user/${userEmail}`);
  };

  const confirmDelete = () => {
    setLoading(true);
    const userService = new UserService();
    userService
      .deleteUser(selectedUser)
      .then(response => {
        if (response.data.responseCode === 200) {
          if (selectedUser === sameUser) {
            dataTransferService.logout();
            setLoading(true);
            navigate('/login');
          } else {
            fetchUsers(organisationName);
            toast.success(response.data.responseMessage, { theme: 'colored' });
          }
        }
        setShowDeleteModal(false);
      })
      .catch(error => {
        setLoading(false);
        handleErrorResponse('delete-user', error.response);
        setShowDeleteModal(false);
      });
  };

  return (
    <div className="custom-container pt-4">
      {loading && <Loader loading={loading} />}

      <p className="fs-4 fw-600">User Management</p>
      <div className="pt-20">
        <p className="fs-14 fw-500 pb-2">
          Select Organization <span className="color-star">*</span>
        </p>
        <DisabledDropdownComponent
          toggleClassName="fs-14 fw-semibold disabled-dropdown custom-arrow"
          selected={organisationName}
        />
      </div>

      <div className="mt-4">
        <button
          type="button"
          className="btn btn-outline custom-btn-outline"
          onClick={handleProceed}
        >
          + Invite User
        </button>

        <div className="mt-4 fs-6 fw-500">
          <div className="d-flex align-items-center justify-content-between">
            <span style={{ flex: 2 }}>Users</span>
            <span style={{ flex: 1 }}>Permission Type</span>
            <span style={{ flex: 1 }}>Location Access</span>
            <span style={{ flex: 1 }}>Action</span>
          </div>
          <hr />

          {users.map(user => (
            <React.Fragment key={user.userEmail}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center" style={{ flex: 2 }}>
                  <img
                    src={
                      user.userProfile
                        ? dataTransferService.getBloburl() + user.userProfile
                        : DefaultProfile
                    }
                    alt="user-profile-icon"
                    className="rounded-circle me-3"
                    width={52}
                    height={52}
                  />
                  <div className="d-flex flex-column">
                    <span>{user.userName}</span>
                    <span>{user.userEmail}</span>
                  </div>
                </div>
                <span style={{ flex: 1 }}>{user.userRole}</span>
                <span style={{ flex: 1 }}>
                  {user.accessibleBranchesCount} locations
                </span>
                <div
                  style={{ flex: 1 }}
                  className="d-flex justify-content-start"
                >
                  <button
                    className="me-3 btn-no-style"
                    onClick={() => handleEditClick(user.userEmail)}
                  >
                    <FontAwesomeIcon icon={faEdit} className="me-1" /> Edit
                  </button>
                  <button
                    className="btn-no-style"
                    onClick={() => handleDeleteClick(user.userMobileNumber)}
                  >
                    <FontAwesomeIcon icon={faTrash} className="me-1" /> Delete
                  </button>
                </div>
              </div>
              <hr />
            </React.Fragment>
          ))}
        </div>
      </div>

      <ConfirmDeleteModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        message="Are you sure you want to delete the user"
        itemName={selectedUser?.userName}
        onConfirm={confirmDelete}
      />
    </div>
  );
};

export default ManageUsers;
