import { useParams } from 'react-router-dom';
import Profile from '../../common/profile';
import UserService from '../../core/service/userService';
import { handleErrorResponse } from '../../core/tokenInterceptor/axiosInstance';
import React, { useCallback, useEffect, useState } from 'react';
import DataTransferService from '../../core/service/dataTransferService';
import Loader from '../../core/loader/loader';
import { ErrorMessage, Field, Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { editUserValidations } from '../../validations/forms';
import {
  DisabledDropdownComponent,
  DropdownComponent
} from '../../common/dropDown';
import { Back } from '../../common/back';

const EditUser = () => {
  const { userEmail } = useParams();
  const [userData, setUserData] = useState(null);
  const [matchingBranchId, setMatchingBranchId] = useState([]);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);

  const dataTransferService = new DataTransferService();
  const selectedOrg = dataTransferService.getSelectedOrganization();
  const organisationDTO = dataTransferService.getOrganisationDTO();
  const selectedBran = dataTransferService.getSelectedBranch();

  const fetchUserData = useCallback(
    email => {
      setLoading(true);
      const userService = new UserService();
      userService
        .fetchUserDate(email, selectedBran, selectedOrg)
        .then(response => {
          if (response.data.responseCode === 200) {
            const userData = response.data.responseBody;
            setUserData(userData);

            const allBranches = getOrganisationBranches(
              userData.organisationBranchMap
            );
            setBranches(allBranches);

            const selectedBranchNames = extractSelectedBranchNames(
              userData.organisationBranchMap
            );

            userService.fetchBranches(selectedOrg).then(branchResponse => {
              handleBranchResponse(branchResponse, selectedBranchNames);
            });
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          handleErrorResponse('admin-fetch-users', error.response);
        });
    },
    [selectedBran, selectedOrg]
  );

  const getOrganisationBranches = organisationBranchMap => {
    let allBranchData = [];
    Object.keys(organisationBranchMap).forEach(key => {
      const branchDataArray = organisationBranchMap[key];
      if (Array.isArray(branchDataArray) && branchDataArray.length > 0) {
        allBranchData = branchDataArray.map(branch => ({
          id: branch.id,
          name: branch.branchName,
          address: branch.branchAddress,
          phone: branch.branchMobileNumber
        }));
      }
    });
    return allBranchData;
  };

  const extractSelectedBranchNames = organisationBranchMap => {
    let selectedBranchNames = [];
    Object.keys(organisationBranchMap).forEach(key => {
      const branchDataArray = organisationBranchMap[key];
      if (Array.isArray(branchDataArray) && branchDataArray.length > 0) {
        selectedBranchNames = [
          ...selectedBranchNames,
          ...branchDataArray.map(branch => branch.branchName)
        ];
      }
    });
    return selectedBranchNames;
  };

  const handleBranchResponse = (response, selectedBranchNames) => {
    if (response.data.responseCode === 200) {
      const branchData = response.data.responseBody.map((branch, index) => ({
        id: index + 1,
        name: branch.branchName,
        address: branch.branchAddress,
        phone: branch.branchMobileNumber
      }));

      setBranches(branchData);

      const matchingBranches = branchData.filter(branch =>
        selectedBranchNames.includes(branch.name)
      );

      if (matchingBranches.length > 0) {
        const matchingBranchIds = matchingBranches.map(branch => branch.id);
        setMatchingBranchId(matchingBranchIds);
      } else {
        setMatchingBranchId([]);
      }
    }
  };

  useEffect(() => {
    if (userEmail) {
      fetchUserData(userEmail);
    }
  }, [userEmail, fetchUserData]);

  const handleBranchSelection = (branch, values, setFieldValue) => {
    const branchId = branch.id;
    if (values.branchNames.includes(branchId)) {
      setFieldValue(
        'branchNames',
        values.branchNames.filter(id => id !== branchId)
      );
    } else {
      setFieldValue('branchNames', [...values.branchNames, branchId]);
    }
  };

  const roleOptions = [
    { id: 1, label: 'Manager', value: 'Manager' },
    { id: 2, label: 'Store_Supervisor', value: 'Store_Supervisor' }
  ];

  const handleSubmit = async values => {
    const getEditedFields = (initial, current) => {
      const editedFields = {};
      Object.keys(current).forEach(key => {
        if (JSON.stringify(initial[key]) !== JSON.stringify(current[key])) {
          editedFields[key] = current[key];
        }
      });
      return editedFields;
    };

    const editedData = getEditedFields(initialValues, values);
    editedData.userEmail = userEmail;
    const hasChanges = Object.keys(editedData).some(key => key !== 'userEmail');

    if (!hasChanges) {
      toast.info('No changes detected.', { theme: 'colored' });
      return;
    }

    setLoading(true);
    const userService = new UserService();
    const orgName = organisationDTO
      ? organisationDTO.organisationName
      : selectedOrg;

    const transformedValues = {
      ...editedData,
      organisationName: orgName,
      userRole: editedData.userRole
        ? editedData.userRole.toUpperCase()
        : undefined,
      branchNames: editedData.branchNames
        ? editedData.branchNames.map(id => {
            const branch = branches.find(branch => branch.id === id);
            return branch ? branch.name : '';
          })
        : undefined
    };

    Object.keys(transformedValues).forEach(
      key =>
        transformedValues[key] === undefined && delete transformedValues[key]
    );

    try {
      const response = await userService.editAcess(
        selectedOrg,
        transformedValues
      );
      if (response.data.responseCode === 200) {
        fetchUserData(userEmail);
        setLoading(false);
        toast.success(`User updated successfully: ${values.userName}`, {
          theme: 'colored'
        });
      }
    } catch (error) {
      setLoading(false);
      handleErrorResponse('edit-user', error.response);
    }
  };

  const initialValues = {
    userName: userData?.userName || '',
    userEmail: userData?.userEmail || '',
    userRole: userData?.userRole || '',
    organisationName: organisationDTO?.organisationName || selectedOrg || '',
    branchNames: matchingBranchId,
    userMobileNumber: userData?.userMobileNumber || ''
  };

  useEffect(() => {
    if (userData) {
      setMatchingBranchId(initialValues.branchNames);
    }
  }, [userData, initialValues.branchNames]);

  return (
    <div className="p-4">
      <div className="d-flex justify-content-between align-items-center pt-3">
        <div className="d-flex align-items-center ps-4">
          <Back />
          <p className="fs-4 fw-500 ps-2">Settings</p>
        </div>
        <Profile />
      </div>

      <div className="mt-5 edit-access-whole-container">
        {loading && <Loader loading={loading} />}

        {userData && (
          <Formik
            initialValues={initialValues}
            validationSchema={editUserValidations}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <Form onSubmit={handleSubmit}>
                <div className="edit-branches-container p-4">
                  <p className="fs-4 fw-600">Edit Employee Data</p>
                  <p className="fs-5 fw-500 mt-4">Employee Details</p>

                  <div className="d-flex align-items-center justify-content-start mt-4">
                    <div className="me-4" style={{ width: '300px' }}>
                      <label className="email-text">
                        Enter Employee Name{' '}
                        <span className="color-star">*</span>
                      </label>
                      <Field
                        type="text"
                        name="userName"
                        placeholder="Enter employee name"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="userName"
                        component="div"
                        className="error-text text-danger"
                      />
                    </div>

                    <div style={{ width: '300px' }}>
                      <label className="email-text">
                        Enter Employee Email{' '}
                        <span className="color-star">*</span>
                      </label>
                      <Field
                        type="email"
                        name="userEmail"
                        placeholder="Enter employee email address"
                        className="form-control"
                        disabled
                      />
                      <ErrorMessage
                        name="userEmail"
                        component="div"
                        className="error-text text-danger"
                      />
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-start mt-4">
                    <div style={{ width: '300px' }}>
                      <label className="email-text">
                        Enter Employee Mobile Number{' '}
                        <span className="color-star">*</span>
                      </label>
                      <Field
                        type="text"
                        name="userMobileNumber"
                        placeholder="Enter employee mobile number"
                        className="form-control"
                        disabled
                      />
                      <ErrorMessage
                        name="userMobileNumber"
                        component="div"
                        className="error-text text-danger"
                      />
                    </div>
                  </div>

                  <div className="note-styling fs-14 fw-normal">
                    Note: This employee can access the billing data, create new
                    bills for services offered.
                  </div>

                  <div className="mt-4">
                    <p className="email-text">
                      Employee Role<span className="color-star">*</span>
                    </p>
                    <DropdownComponent
                      selected={values.userRole || 'Select Role'}
                      options={roleOptions}
                      onSelect={selectedOption =>
                        setFieldValue('userRole', selectedOption)
                      }
                      toggleClassName="fs-14 fw-semibold custom-invite-dropdown custom-arrow"
                    />
                    <ErrorMessage
                      name="userRole"
                      component="div"
                      className="error-text text-danger"
                    />
                  </div>

                  <div className="mt-4">
                    <p className="email-text">
                      Select Organization<span className="color-star">*</span>
                    </p>
                    <DisabledDropdownComponent
                      toggleClassName="fs-14 fw-bold disabled-dropdown custom-arrow"
                      selected={selectedOrg}
                    />
                  </div>

                  <div className="select-branches-outer-container mt-4 d-flex flex-column p-5 pb-32">
                    <div className="row">
                      {branches.map(branch => (
                        <div key={branch.id} className="col-md-4 mb-3 d-flex">
                          <div
                            className={`select-branches-inner-container pt-3 ps-4 d-flex align-items-center justify-content-evenly branch-data ${
                              values.branchNames.includes(branch.id)
                                ? 'selected'
                                : ''
                            }`}
                          >
                            <div className="branch-fields">
                              <p className="fs-6 fw-500">{branch.name}</p>
                              <p className="fs-15 fw-400">{branch.address}</p>
                              <p className="fs-14 fw-400">Ph: {branch.phone}</p>
                            </div>
                            <input
                              type="checkbox"
                              checked={values.branchNames.includes(branch.id)}
                              onChange={() =>
                                handleBranchSelection(
                                  branch,
                                  values,
                                  setFieldValue
                                )
                              }
                              className="ms-3"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                    <ErrorMessage
                      name="branchNames"
                      component="div"
                      className="error-text text-danger"
                    />
                  </div>

                  <div className="d-flex align-items-center justify-content-end mt-5">
                    <button type="submit" className="p-2 button-teal w-25">
                      Proceed
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default EditUser;
